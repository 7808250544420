/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import { Text, useColorMode, Flex, Box, Tooltip } from "@chakra-ui/react";
import { type FC } from "react";
import { type IEditorIcons } from "typings/interface";
import { iconsMap } from "./IconsMap";

const EditorIcons: FC<IEditorIcons> = ({ name, value, handleOpenDrawer, isActive }: IEditorIcons) => {
  const { colorMode } = useColorMode();
  const Icon = iconsMap[value];

  const openDrawer = () => handleOpenDrawer(value); 
  const nothing = () => {}; 

  const Display = (
    <Flex
      flexDirection={"column"}
      onClick={isActive ? openDrawer : nothing}
      flexShrink={0}
      justify={"center"}
      align={"center"}
      h={"fit-content"}
      gap={1}
      px={1}
      w={"fit-content"}
    >
      <Box
        cursor={isActive ? "pointer" : "not-allowed"}
      >
        <Icon mode={colorMode} />
      </Box>
      {/* <Image
        src={`/create/${value}.webp`}
        w={"24px"}
        h={"20px"}
        alt={"colour"}
        filter={colorMode === "light" ? "invert(100)" : "invert(0)"}
        loading={"lazy"}
      /> */}
      <Text fontSize={"12"} fontWeight={'medium'}>{name === 'Social Icons' ? 'Socials' : name}</Text>
    </Flex>
  );

  return (
    isActive ? 
      Display
    :
    <Tooltip label='you can only add one group section'>
      {Display}
    </Tooltip>
  );
};

export default EditorIcons;
