import * as yup from 'yup'

import {
  Box,
  Link as Anchor,
  Stack,
  Text,
  useColorMode,
  useToast,
  Flex,
  // VStack,
} from '@chakra-ui/react'
import { Button, WithAddon as InputWithAddon, PlainInput } from 'components'
import PasswordInput from 'components/Input/Password'
import { type FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'routes/links'
// import SocialLogin from '../SocialLogin'
import { SIGNUP_MUTATION } from 'apolloClient/mutations/userSignup'
import { PLATFORM_SIGNUP_MUTATION } from 'apolloClient/mutations/platformSignup'
import { useMutation, useQuery } from '@apollo/client'
import { USERNAME_CHECK_QUERY } from 'apolloClient/queries/usernameCheck'
import { type RegisterTypes } from 'typings/interface'
import { GROUP_SIGNUP_MUTATION } from 'apolloClient/mutations/groupSignup'

const strLen = (str: string) => {
  const len = str.length
  const index = Math.floor(0.8 * len)
  return str.substring(0, index)
}

const RegisterForm: FC<{ type?: RegisterTypes }> = ({ type }) => {
  const { colorMode } = useColorMode()
  const navigate = useNavigate()
  const toast = useToast({
    position: 'top',
    containerStyle: {
      zIndex: 9,
    },
  })

  const [email, setEmail] = useState('')
  const [platformUserName, setPlatformUserName] = useState('')

  const [suggestedUsername, setSuggestedUsername] = useState({
    username1: '',
    username2: '',
  })

  const [username, setUsername] = useState('')
  const [emailErrMessage, setEmailErrMessage] = useState('')

  const [password, setPassword] = useState('')
  const [passwordErrMessage, setPasswordErrMessage] = useState('')

  const [usernameExists, setUsernameExists] = useState(0)

  const [signupMutation, { loading }] = useMutation(SIGNUP_MUTATION)
  const [platformSignupMutation, { loading: platformLoading }] = useMutation(
    PLATFORM_SIGNUP_MUTATION,
  )
  const [groupSignupMutation, { loading: groupLoading }] = useMutation(
    GROUP_SIGNUP_MUTATION,
  )

  const emailSchema = yup
    .string()
    .email('It must be a valid email')
    .required('Provide a valid email address')

  const handleNameChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ): void => {
    e.preventDefault()
    setPlatformUserName(e.target.value)
  }

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ): void => {
    e.preventDefault()
    let val = ''
    if (e.target.value) {
      val = e.target.value.toLowerCase().replace(' ', '')
    }
    ;(async () => {
      try {
        await emailSchema.validate(val)
        setEmailErrMessage('')
      } catch (error: any) {
        const yupError = error as yup.ValidationError
        setEmailErrMessage(yupError?.message || 'email invalid')
      }
    })()
    setEmail(val)

    const name = val.split('@')?.[0].replace(/\s+/g, '').replace(/[^a-zA-Z0-9]/g, '')
    setSuggestedUsername({
      username1: name,
      username2: strLen(name),
    })
  }

  const handleUsernameChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ): void => {
    e.preventDefault()
    let val = ''
    if (e.target.value) {
      val = e.target.value
        .toLowerCase()
        .replace(/\s+/g, '')
        .replace(/[^a-zA-Z0-9]/g, '')
    }
    if (val.length <= 20) setUsername(val)
  }

  const passwordSchema = yup
    .string()
    .min(8, 'Minimum of 8 characters')
    .required('Please Enter your password')

  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ): void => {
    e.preventDefault()
    const val = e.target.value
    ;(async () => {
      try {
        await passwordSchema.validate(val)
        setPasswordErrMessage('')
      } catch (err: any) {
        const yupError = err as yup.ValidationError
        setPasswordErrMessage(yupError?.message || 'password invalid')
      }
      setPassword(val)
    })()
  }

  const { refetch } = useQuery(USERNAME_CHECK_QUERY, {
    variables: { username: '' },
  })

  const [usernameCheckLoading, setUsernameCheckLoading] = useState(false)

  const checkUsername = (e: any): void => {
    if (e) {
      e.preventDefault()
      if (!e.target.value) return
    }

    if (e.target.value.length <= 1) {
      toast({
        status: 'error',
        description: 'username must be greater than 1 character',
      })
      return
    }

    setUsernameCheckLoading(true)
    refetch({ username: e.target.value })
      .then(() => {
        setUsernameExists(2)
        setUsernameCheckLoading(false)
      })
      .catch(() => {
        setUsernameExists(1)
        setUsernameCheckLoading(false)
      })
  }

  const handleSubmit = () => {
    const input = {
      email,
      username,
      password,
    }

    const platformInput = {
      ...input,
      name: platformUserName,
    }

    if (!email) {
      toast({
        status: 'error',
        description: 'email is required',
      })
    }

    if (!username) {
      toast({
        status: 'error',
        description: 'username is required',
      })
    }

    if (!password) {
      toast({
        status: 'error',
        description: 'password is required',
      })
    }

    if (type !== 'individual' && !platformUserName) {
      toast({
        status: 'error',
        description: 'name is required',
      })
    }

    if (
      !email ||
      !username ||
      !password ||
      (type !== 'individual' && !platformUserName)
    )
      return

    type === 'platform'
      ? platformSignupMutation({
          variables: { input: platformInput },
          onCompleted: () => {
            // TODO: discuss how to make the login screen know to show verification link banner immediately after signup
            navigate(ROUTES.login)
          },
          onError: error => {
            toast({
              status: 'error',
              description:
                (error as any)?.networkError?.result?.message ||
                'cannot register user',
            })
          },
        })
      : type === 'group'
      ? groupSignupMutation({
          variables: { input: platformInput },
          onCompleted: () => {
            // TODO: discuss how to make the login screen know to show verification link banner immediately after signup
            navigate(ROUTES.login)
          },
          onError: error => {
            toast({
              status: 'error',
              description:
                (error as any)?.networkError?.result?.message ||
                'cannot register user',
            })
          },
        })
      : signupMutation({
          variables: { input },
          onCompleted: () => {
            // TODO: discuss how to make the login screen know to show verification link banner immediately after signup
            navigate(ROUTES.login)
          },
          onError: error => {
            toast({
              status: 'error',
              description:
                (error as any)?.networkError?.result?.message ||
                'cannot register user',
            })
          },
        })
  }

  return (
    <Stack spacing={4}>
      <Box w={'100%'}>
        {type !== 'individual' && (
          <Box w={'full'}>
            <PlainInput
              placeholder="Name"
              name="name"
              value={platformUserName}
              type="text"
              disabled={false}
              showMessage={true}
              onChange={handleNameChange}
            />
          </Box>
        )}
        <Box w={'full'}>
          <PlainInput
            placeholder="Email address"
            name="email"
            value={email}
            type="email"
            disabled={false}
            showMessage={true}
            errorMessage={emailErrMessage}
            onChange={handleEmailChange}
          />
        </Box>
        <Box w={'full'}>
          <InputWithAddon
            placeholder="enter your username"
            name="username"
            value={username}
            type="text"
            disabled={false}
            showMessage={true}
            leftAddon={'peiges.co/'}
            isRegisterCheck={usernameCheckLoading}
            usernameExists={usernameExists}
            onChange={handleUsernameChange}
            onBlur={checkUsername}
          />
        </Box>
        <Flex gap={'10px'} mb={'4'}>
          <Text>suggestions: </Text>
          <Text
            cursor={'pointer'}
            textDecoration={'underline'}
            textDecorationStyle={'dotted'}
            onClick={() => {
              setUsername(suggestedUsername.username1)
              checkUsername({
                target: {
                  value: suggestedUsername.username1,
                },
                preventDefault() {},
              })
            }}
          >
            {suggestedUsername.username1}
          </Text>
          <Text
            cursor={'pointer'}
            textDecoration={'underline'}
            textDecorationStyle={'dotted'}
            onClick={() => {
              setUsername(suggestedUsername.username2)
              checkUsername({
                target: {
                  value: suggestedUsername.username2,
                },
                preventDefault() {},
              })
            }}
          >
            {suggestedUsername.username2}
          </Text>
        </Flex>
        <Box width={{ base: '100%' }}>
          <PasswordInput
            placeholder="Set password"
            name="password"
            value={password}
            disabled={false}
            showMessage={true}
            errorMessage={passwordErrMessage}
            onChange={handlePasswordChange}
          />
        </Box>
        <Box width={'full'} mt={2}>
          <Button
            text={'Sign up'}
            isLoading={loading || platformLoading || groupLoading}
            w={'full'}
            h={'50px'}
            borderRadius={'8px'}
            maxW={'full'}
            fontWeight={'500'}
            fontSize={'16px'}
            onClick={handleSubmit}
          />
        </Box>
      </Box>
      {/* <VStack>
          <Box width={'full'} mt="25px" className={'border-divider'}>
            <Text textAlign={'center'} fontSize={'16px'}>
              Or sign up with
            </Text>
          </Box>
          <Box width={'full'} mt={2}>
            <SocialLogin authText="Sign up" />
          </Box>
      </VStack> */}
      <Stack pt={6}>
        <Text align={'center'}>
          Already a user?{' '}
          <Anchor
            href={`/auth/login${type === 'individual' ? '' : `?${type}`}`}
            color={colorMode === 'light' ? '#000000' : '#F5CE6F'}
          >
            Sign in
          </Anchor>
        </Text>
      </Stack>
    </Stack>
  )
}

export default RegisterForm
