import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
      /* Beadly */
      @font-face {
        font-family: 'Beadly';
        font-weight: 400;
        src: url('./fonts/Beadly.otf') format('opentype')
      }
      `}
  />
)

export default Fonts