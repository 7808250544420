import Cookies from 'js-cookie';

const prepareArray = (arr: any[], allowEmptyString: boolean): any[] => {
  return arr.map((v) => prepareVal(v, allowEmptyString)).filter(v => v !== null);
};

const prepareVal = (val: any, allowEmptyString: boolean) => {
  if (!val) {
    if (val === 0 || val === false) return val;
    if (allowEmptyString) {
      if (typeof val === "string" && val === "") return val;
    }
  }else {
    if (typeof val === "object") {
      if (Array.isArray(val)) {
        if (Array.isArray(val)) return prepareArray(val, allowEmptyString);
      }else return prepareObject(val, allowEmptyString);
    }else return val;
  }
  return null;
};

const prepareObject = (obj: Record<string, any>, allowEmptyString: boolean ) => {
  const res: Record<string, any> = {};
  for (const [key, val] of Object.entries(obj)) {
    const valRes = prepareVal(val, allowEmptyString);
    if (valRes !== null) res[key] = valRes;
  }
  return res;
};

const convertObject = (obj: Record<string, any>) => {
  let res = "";
  if (Object.keys(obj).length === 0) return res;
  for (const [key, val] of Object.entries(obj)) res += `${res ? '&' : ''}${key}=${val}`;
  return res;
};

const prepareRequest = ({baseRoute, ext, params, query}: Partial<BASE_API_REQ>, allowEmptyString: boolean) =>
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  (baseRoute || process.env.REACT_APP_API_BASE_URL) + 
    (ext ? `/${ext}` : '') +
    (params ? `/${params}` : '') +
    (query ? `?${convertObject(prepareObject(query, allowEmptyString))}` : '');

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type BASE_API_REQ = {
  baseRoute: string; // doesn't ends with /
  ext: string;
  params: string;
  query: Record<string, string | number | undefined>;
  body: FormData | string | Record<string, any>,
};

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type ResponseType<T> = {
  statusCode: number, 
  message: string, 
  payload: T,
  errors: string | null,
  token: string | null,
};

export type responseCallback<T> = (response: ResponseType<T>) => void;

const REQ_METHOD = <T>(
  reqMethod: 'POST' | 'GET' | 'PUT' | 'DELETE',
  baseApiReq: Partial<BASE_API_REQ>,
  responseCallback: responseCallback<T>,
  allowEmptyString: boolean
) => {
  const { body } = baseApiReq;
  const reqUrl = prepareRequest(baseApiReq, allowEmptyString);
  const bodyIsOriginallyStringOrFormData = (typeof body === "string") || (body instanceof FormData);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-unnecessary-type-assertion
  const reqBody = body ? (bodyIsOriginallyStringOrFormData ? body : JSON.stringify(prepareObject(body!, allowEmptyString))) : "";

  const accessToken = Cookies.get("token") ?? sessionStorage.getItem("token")
  const payload = {
    method: reqMethod,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ...(!bodyIsOriginallyStringOrFormData ? { "Content-Type": "application/json"} : {} ),
    },
    ...(body ? {body: reqBody} : {}),
  };

  (async () => {
    const res = await fetch(reqUrl, payload);
    const data = (await res.json()) as ResponseType<T>;
    responseCallback(data);
  })();
};


export const POST = <T>(baseApiReq: Partial<BASE_API_REQ>, responseCallback: responseCallback<T>, allowEmptyString = false) => 
  // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
  REQ_METHOD<T>("POST", baseApiReq, responseCallback, allowEmptyString);

export const PUT = <T>(baseApiReq: Partial<BASE_API_REQ>, responseCallback: responseCallback<T>, allowEmptyString = false) => 
  // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
  REQ_METHOD<T>("PUT", baseApiReq, responseCallback, allowEmptyString);

export const DELETE = <T>(baseApiReq: Partial<BASE_API_REQ>, responseCallback: responseCallback<T>, allowEmptyString = false) => 
  // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
  REQ_METHOD<T>("DELETE", baseApiReq, responseCallback, allowEmptyString);

export const GET = <T>(baseApiReq: Partial<BASE_API_REQ>, responseCallback: responseCallback<T>, allowEmptyString = false) => 
  // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
  REQ_METHOD<T>("GET", baseApiReq, responseCallback, allowEmptyString);
