import { gql } from '@apollo/client';

export const LINK_CLICK_QUERY = gql`
  query LinkClicks {
    linkClick @rest(type: "LinkClicks", path: "/metrics/link-click") {
      statusCode
      message
      payload {
        lastWeekData {
            key
            views
            date
            day
            month
        }
        lastThirtyDaysData {
            key
            views
            date
            day
            month
        }
        lastYearData {
            key
            views
            date
            day
            month
        }
      }
    }
  }
`;