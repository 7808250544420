import {
  Box,
  Image,
  Input as InputCmp,
  InputGroup,
  Text,
  InputLeftAddon,
  InputRightAddon,
  Spinner,
  useColorMode,
} from '@chakra-ui/react'
import { type FC, useState } from 'react'
import { useFormikContext } from 'formik'
import theme from 'theme'
import './input.css'
import { type IInput } from 'typings/interface'

const WithAddon: FC<IInput> = ({
  placeholder,
  id,
  background,
  border,
  required,
  type,
  name,
  disabled,
  value,
  values,
  onChange,
  onBlur,
  errorMessage,
  showMessage,
  color,
  width,
  borderRadius,
  hideCaret,
  _focus,
  leftAddon,
  rightAddon,
  isNotLowercase,
  usernameExists,
  isRegisterCheck,
  showLabel,
}: IInput) => {
  const [isActive, setIsActive] = useState(false)
  const { colorMode } = useColorMode()
  function handleTextChange(text: string): void {
    if (text !== '') {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }

  const error = theme.colors.default.error
  const borderColor =
    colorMode === 'dark'
      ? theme.colors.border.default
      : theme.colors.border._dark
  const bg = theme.colors.text.primary.default
  const textColor = theme.colors.text.primary._dark

  return (
    <>
      <Box h={'50px'}>
        <InputGroup
          border={isRegisterCheck ? '1px solid' : 0}
          borderColor={isRegisterCheck ? '#F5CE6F' : 'transparent'}
          borderRadius={'8px'}
        >
          {leftAddon && (
            <InputLeftAddon
              id={'input-addon'}
              h={'50px !important'}
              pr={0}
              border={!isRegisterCheck ? '1px solid' : 0}
              borderRight={0}
              borderColor={borderColor}
              bg={background ?? bg}
              color={color ?? textColor}
            >
              {leftAddon}
            </InputLeftAddon>
          )}
          <Box
            position={'relative'}
            display="flex"
            flexDirection={'column'}
            id="custom-input-wrapper"
            className="custom-input-addon-wrapper"
            outline="none"
            h={'50px !important'}
            borderRadius={borderRadius ?? '0px 7px 7px 0px'}
            w={width ?? 'full'}
            background={background ?? bg}
            border={
              border ??
              (errorMessage
                ? '1px solid #EB001B !important'
                : !isRegisterCheck
                ? '1px solid'
                : 0)
            }
            borderColor={borderColor}
            borderLeftColor={"transparent"}
            overflow={'hidden'}
          >
            <InputCmp
              sx={{ caretColor: `${hideCaret ? 'transparent' : ''}` }}
              id={id ?? 'input-field'}
              className={isNotLowercase ? 'input-reg-field input-field' : 'input-reg-field input-addon-field input-field'}
              autoComplete="off"
              padding={'1.55rem 2px'}
              bg={background ?? bg}
              placeholder={!showLabel ? placeholder : ''}
              required={required ?? false}
              type={type ?? 'text'}
              name={name}
              disabled={disabled ?? false}
              value={values ? values[name] : value}
              onChange={(e) => {
                onChange?.(e)
                handleTextChange(e.target.value)
              }}
              onBlur={(e: any) => onBlur?.(e)}
              border={0}
              _hover={{
                border: 0,
              }}
              _focus={{
                boxShadow: 'none',
                border: errorMessage ? '0' : _focus ?? 0,
                borderRadius: borderRadius ?? '0px 7px 7px 0px',
              }}
              _disabled={{ opacity: 0.9 }}
              fontSize={'15px'}
              color={color ?? `${theme.colors.default.gray}`}
              fontWeight="400"
              _placeholder={{
                color: color ?? theme.colors.default.gray,
              }}
            />
            {isRegisterCheck ? (
              <Box
                pos={'absolute'}
                top={'17px'}
                right={'8px'}
                bg={bg}
                borderRadius={'50%'}
                w={'20px'}
                h={'20px'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                zIndex={2}
              >
                <Spinner color={'black'} size={'xs'} />
              </Box>
            ) : (
              <Box
                pos={'absolute'}
                top={'14px'}
                right={'8px'}
                bg={bg}
                w={'24px'}
                h={'24px'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                zIndex={2}
              >
                {usernameExists === 2 && (
                  <Image
                    w={'20px'}
                    h={'20px'}
                    objectFit={'contain'}
                    src={'/images/tick.webp'}
                    alt={'username available'}
                    loading={'lazy'}
                  />
                )}
                {usernameExists === 1 && (
                  <Image
                    w={'20px'}
                    h={'20px'}
                    objectFit={'contain'}
                    src={'/images/cross.webp'}
                    alt={'username unavailable'}
                    loading={'lazy'}
                  />
                )}
              </Box>
            )}
            {showLabel && (
              <label
                id="input-addon-label"
                htmlFor={type ?? 'text'}
                className={isActive ? 'Active' : ''}
                style={{
                  transform: `${
                    value ?? values?.[name]
                      ? 'translate(2px, 1px) scale(0.6)'
                      : ''
                  }`,
                  zIndex: `${value ?? values?.[name] ? '2' : ''}`,
                }}
              >
                {placeholder}
              </label>
            )}
          </Box>
          {rightAddon && <InputRightAddon>{rightAddon}</InputRightAddon>}
        </InputGroup>
      </Box>
      <Box textAlign={'left'} padding="0.3rem 0">
        <Text color={error} fontSize={'12px'} fontWeight="500">
          {showMessage
            ? usernameExists === 1
              ? `Not available, try again`
              : errorMessage
            : ''}
        </Text>
      </Box>
    </>
  )
}

export const FormikFormInputWithAddon: FC<
  IInput & {
    name: string
    showMessage: boolean
    value?: any
    isNumericFormat?: boolean
    hideCaret?: boolean
  }
> = ({ name, showMessage, value, isNumericFormat, hideCaret, ...rest }) => {
  const { handleChange, values, errors, setFieldValue } =
    useFormikContext<any>()
  const hasError = errors[name] ?? ''

  return (
    <WithAddon
      {...rest}
      isNumericFormat={isNumericFormat}
      setFieldValue={setFieldValue}
      name={name}
      onChange={handleChange}
      value={values[name]}
      showMessage={showMessage}
      errorMessage={hasError}
      hideCaret={hideCaret}
    />
  )
}

export default WithAddon
