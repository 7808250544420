import { gql } from '@apollo/client';

export const RESET_PASSWORD_MUTATION = gql`
  fragment ResetPasswordInput on REST {
    email: String
  }

  mutation ResetPassword($input: ResetPasswordInput!) {
    reset(input: $input) @rest(type: "ResetPassword", method: "POST", path: "/auth/reset-password-individual") {
      statusCode
      message
      payload {
        email
      }
    }
  }
`;