import { gql } from '@apollo/client';

export const PUBLISH_PEIGE_LIVE_MUTATION = gql`

  mutation PublishPeigeLive {
    publishPeigeLive(input: {}) @rest(type: "PublishPeigeLive", method: "POST", path: "/peige/publish-live") {
      statusCode
      message
    }
  }
`;
