import { Box, Flex, Link, Image, Text } from "@chakra-ui/react";

interface FooterProps {
  color: 'white' | 'black'
  GroupBrand?: JSX.Element
}

const Footer = ({ color, GroupBrand }: FooterProps) => {
  return (
    <Box
      w={"full"}
      h={"45px"}
      pos={"relative"}
      p={{ base: "20px 16px", sm: "20px 24px" }}
      mt={"auto"}
    >
      <Flex flexDir={"row"} justify={"center"} align={"center"} m={"0 auto"}>
        {
          !GroupBrand && <Text mr={'0.5rem'}>made with</Text>
        }
        <Link href="https://peiges.co" isExternal textDecoration={"none"}>
          <Image
            src={color === "white" ? "/images/peiges_light.webp" : "/images/peiges_dark.webp"}
            w={'5rem'}
            h={'1.5rem'}
            alt={"made with peiges"}
            loading={"lazy"}
          />
        </Link>
        {
          GroupBrand && (
            <Flex
              justify={"center"} 
              align={"center"} 
              m={"0 auto"} 
              ml={'0.5rem'}
              gap={'0.5rem'}
            >
              <Image
                src={color === "white" ? "/images/partnerX-white.webp" : "/images/partnerX-black.webp"}
                w={'2rem'}
                h={'2rem'}
                alt={"made with peiges"}
                loading={"lazy"}
              />
              {GroupBrand}
            </Flex>
          )
        }
      </Flex>
    </Box>
  );
};

export default Footer;
