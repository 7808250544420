import { Box, Select, Text, useColorMode } from "@chakra-ui/react";
import { type FC } from "react";
import { useFormikContext } from "formik";
import { type ISelect } from "typings/interface";
import theme from "theme";

const SelectCmp: FC<ISelect> = ({
  placeholder,
  items,
  handleChange,
  background,
  required,
  name,
  disabled,
  height,
  value,
  values,
  errorMessage,
  showMessage,
  borderRadius,
}: ISelect) => {
  const {colorMode} = useColorMode();
  const errorColor = theme.colors.default.error;
  const themeWhite = theme.colors.default.white;
  const darkBg = theme.colors.background.primary._dark;

  return (
    <>
      <Box
        position={"relative"}
        display="flex"
        flexDirection={"column"}
        id="custom-select-wrapper"
        outline={"none"}
        height={height ? `${height} !Important` : ""}
        borderRadius={borderRadius ?? "18px"}
        background={background ?? darkBg}
        overflow={"hidden"}
        padding={0}
        border={errorMessage ? `1px solid ${errorColor} !important` : `none`}
        justifyContent={'center'}
      >
        <Select
          value={values ? values[name] : value}
          paddingInlineStart={"0 !Important"}
          paddingInlineEnd={"0 !Important"}
          padding={{base: "0px 10px", md:"10px 16px"}}
          background={"transparent"}
          id="input-select"
          placeholder={placeholder}
          onChange={handleChange}
          required={required}
          name={name}
          disabled={disabled ?? false}
          border={0}
          borderRadius={"0"}
          _hover={{
            border: 0,
          }}
          _focus={{
            boxShadow: "none",
            borderRadius: borderRadius ?? "18px",
          }}
          _disabled={{ opacity: 0.9 }}
          fontSize={"15px"}
          color={themeWhite}
          fontWeight="400"
        >
          {Array.isArray(items) &&
            items?.map((item) => (
              <option
                key={item.value}
                value={item.value}
                className={`select-option ${colorMode === 'light' ? 'light-select' : 'dark-select'}`}
              >
                {item.text}
              </option>
            ))}
        </Select>
      </Box>
      <Box textAlign={"left"} padding="0.3rem 0">
        <Text color={errorColor} fontSize={"12px"} fontWeight="400" pl="1rem">
          {showMessage ? errorMessage : ""}
        </Text>
      </Box>
    </>
  );
};

export const FormikFormSelect: FC<
  Omit<ISelect, "handleChange"> & {
    name: string;
    showMessage: boolean;
    items: Array<{ text: string | number; value: any }>;
    customHandleChange?: ((e: any) => void) | undefined;
  }
> = ({ name, showMessage, items, customHandleChange, ...rest }) => {
  const { handleChange, values, errors } = useFormikContext<any>();
  const hasError = errors[name] ?? "";

  return (
    <SelectCmp
      {...rest}
      name={name}
      items={items}
      handleChange={(e) => {
        handleChange(e);
        customHandleChange?.(e);
      }}
      value={(values)[name]}
      showMessage={showMessage}
      errorMessage={hasError}
    />
  );
};

export default SelectCmp;
