import { type IIcon } from "./icon";


export const TextIcon = ({mode}: IIcon) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 24" fill="none">
      <path d="M8 6.5C7.80109 6.5 7.61032 6.57243 7.46967 6.70136C7.32902 6.8303 7.25 7.00516 7.25 7.1875C7.25 7.36984
       7.32902 7.5447 7.46967 7.67364C7.61032 7.80257 7.80109 7.875 8 7.875H12.5H17C17.1989 7.875 17.3897 7.80257 17.5303 
       7.67364C17.671 7.5447 17.75 7.36984 17.75 7.1875C17.75 7.00516 17.671 6.8303 17.5303 6.70136C17.3897 6.57243 17.1989 
       6.5 17 6.5H8ZM7.25 9.9375C7.25 9.75516 7.32902 9.5803 7.46967 9.45136C7.61032 9.32243 7.80109 9.25 8 9.25H17C17.1989 
       9.25 17.3897 9.32243 17.5303 9.45136C17.671 9.5803 17.75 9.75516 17.75 9.9375C17.75 10.1198 17.671 10.2947 17.5303 
       10.4236C17.3897 10.5526 17.1989 10.625 17 10.625H8C7.80109 10.625 7.61032 10.5526 7.46967 10.4236C7.32902 10.2947 7.25
        10.1198 7.25 9.9375ZM8 12C7.80109 12 7.61032 12.0724 7.46967 12.2014C7.32902 12.3303 7.25 12.5052 7.25 12.6875C7.25 12.8698 
        7.32902 13.0447 7.46967 13.1736C7.61032 13.3026 7.80109 13.375 8 13.375H17C17.1989 13.375 17.3897 13.3026 17.5303 13.1736C17.671 
        13.0447 17.75 12.8698 17.75 12.6875C17.75 12.5052 17.671 12.3303 17.5303 12.2014C17.3897 12.0724 17.1989 12 17 12H8ZM8 14.75C7.80109 14.75 7.61032
         14.8224 7.46967 14.9514C7.32902 15.0803 7.25 15.2552 7.25 15.4375C7.25 15.6198 7.32902 15.7947 7.46967 15.9236C7.61032 16.0526 7.80109 16.125 8 
         16.125H12.5C12.6989 16.125 12.8897 16.0526 13.0303 15.9236C13.171 15.7947 13.25 15.6198 13.25 15.4375C13.25 15.2552 13.171 15.0803 13.0303 
         14.9514C12.8897 14.8224 12.6989 14.75 12.5 14.75H8Z" fill={mode === 'light' ? "#040404" : "#F5F5F5"}/>
      <path d="M3 3.75C3 3.02065 3.43739 2.01573 4 1.5C4.56261 0.984275 5.70435 1 6.5 1L18.5 1C19.2957 1 20.4374 0.984275 21 1.5C21.5626 2.01573 22 3.02065
       22 3.75V20.25C22 20.9793 21.5626 21.9843 21 22.5C20.4374 23.0157 19.2957 23 18.5 23H6.5C5.70435 23 4.56261 23.0157 4 22.5C3.43739 21.9843 3 20.9793
        3 20.25V3.75ZM18.5 2.375H6.5C6.10218 2.375 5.72064 2.51987 5.43934 2.77773C5.15804 3.03559 5 3.38533 5 3.75V20.25C5 20.6147 5.15804 20.9644 5.43934 
        21.2223C5.72064 21.4801 6.10218 21.625 6.5 21.625H18.5C18.8978 21.625 19.2794 21.4801 19.5607 21.2223C19.842 20.9644 20 20.6147 20 20.25V3.75C20 
        3.38533 19.842 3.03559 19.5607 2.77773C19.2794 2.51987 18.8978 2.375 18.5 2.375Z" fill={mode === 'light' ? "#040404" : "#F5F5F5"}/>
    </svg>
  );
};
