import Cookies from 'js-cookie'
import localforage from 'localforage';

export const logout = async (): Promise<void> => {
  localStorage.clear();
  sessionStorage.removeItem('token');
  await localforage.dropInstance({ name: "Pending Invite Closed" });
  // Cookies.remove('token', { path: '/', domain: `${process.env.REACT_APP_DOMAIN}` })
  Cookies.remove('token', { secure: true });
  const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)');
  if (isDarkMode) localStorage.setItem('chakra-ui-color-mode', 'dark')
  window.location.href = '/login'
}

export const tokenExpired = async (): Promise<void> => {
  sessionStorage.removeItem('token');
  await localforage.dropInstance({ name: "Pending Invite Closed" });
  Cookies.remove('token', { path: '/', domain: `${process.env.REACT_APP_DOMAIN}` })
}

export const tokenExpiredSync = () => {
  sessionStorage.removeItem('token');
  localforage.dropInstance({ name: "Pending Invite Closed" });
  Cookies.remove('token', { path: '/', domain: `${process.env.REACT_APP_DOMAIN}` })
}
