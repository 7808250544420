/* eslint-disable no-useless-escape */
import * as yup from "yup";

const registerSchema = yup.object().shape({
  email: yup
    .string()
    .email("It must be a valid email")
    .required("Provide a valid email address"),
  password: yup
    .string()
    .min(8, "Minimum of 8 characters")
    .required("Please Enter your password"),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  //   "Must Contain minimum 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
  // ),
});

export default registerSchema;
