import { Flex, useColorModeValue } from '@chakra-ui/react'
import { type FC } from 'react'
import { Outlet } from 'react-router-dom'
import theme from 'theme'

const Auth: FC = () => {
  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue(
        theme.colors.background.primary.default,
        theme.colors.background.primary._dark,
      )}
    >
      <Outlet />
    </Flex>
  )
}

export default Auth
