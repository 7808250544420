import { gql } from '@apollo/client'
import { EditorResponse } from './editorResponse'

export const UPDATE_EDITOR_MUTATION = gql`
  fragment TextInput on REST {
    value: String
    font: String
    size: String
  }
  fragment ImageInput on REST {
    value: String
    style: String
  }
  fragment TitleInput on REST {
    heading: String
    subHeading: String
    alignment: String
  }
  fragment IconInput on REST {
    value: String
  }
  fragment SocialsItem on REST {
    name: String
    handle: String
    url: String
  }
  fragment SocialsInput on REST {
    apps: SocialsItem
    iconStyle: String
    alignment: String
  }
  fragment ButtonInput on REST {
    text: String
    url: String
    addCTA: Boolean
  }
  fragment ConfigurationInput on REST {
    text: TextInput
    image: ImageInput
    title: TitleInput
    icon: IconInput
    socials: SocialsInput
    url: String
    layout: String
    groupID: String
    groupTitle: String
    groupDescription: String
    figureSetting: String
    button: ButtonInput
  }
  fragment SectionInput on REST {
    configuration: ConfigurationInput
    _id: String
    kind: String
    peigeID: String
    individualID: String
    displayStatus: String
    createdAt: String
    updatedAt: String
  }

  fragment UpdateEditorInput on REST {
    peigeID: String
    sections: SectionInput
  }

  mutation UpdateEditor($input: UpdateEditorInput!) {
    updateEditor(input: $input)
      @rest(type: "UpdateEditor", method: "PUT", path: "/editor/graphql") {
      ${EditorResponse}
    }
  }
`
