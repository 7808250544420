import { gql } from '@apollo/client';

export const USERNAME_CHECK_QUERY = gql`
  query UsernameCheck($username: String!) {
    usernameChecker(username: $username) @rest(type: "UsernameCheck", path: "/individual/check/username?username={args.username}") {
      statusCode
      message
      payload
    }
  }
`;