import { Flex } from '@chakra-ui/react'
import { type FC } from 'react'
import { Outlet } from 'react-router-dom'
import { type ViewContext } from 'utils/store/editor.collection'

interface ContentInterface {
  isEditor: boolean
  view: ViewContext
}

const Content: FC<ContentInterface> = ({
  isEditor,
  view,
}: ContentInterface) => {
  const contentContext = { view }
  return (
    <Flex
      className={'no-scrollbar'}
      flexDir={'column'}
      w={'full'}
      h={{
        base: isEditor ? 'calc(100vh - 130px)' : 'full',
        md: isEditor ? 'calc(100vh - 70px)' : 'full',
      }}
      p={isEditor ? 0 : '1rem'}
      m={'0 auto'}
      pos={'relative'}
      overflowY={'auto'}
      top={{ base: '70px', md: 0 }}
    >
      <Flex
        w={'full'}
        minH={{
          base: isEditor ? 'calc(100vh - 130px)' : 'unset',
          md: isEditor ? 'calc(100vh - 70px)' : 'unset',
        }}
        maxW={'1160px'}
        m={'0 auto'}
      >
        <Outlet context={contentContext} />
      </Flex>
    </Flex>
  )
}

export default Content