import { Flex } from "@chakra-ui/react";
import Loader from "components/Loader";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CurrentUserContext } from "routes/AuthRouteProvider";
import { ROUTES } from "routes/links";
import { type GetIndividualV2 } from "routes/types";
import { POST } from "utils/base.api";


const GroupInvite = () => {
  const { setCurrentUser } = useContext(CurrentUserContext);
  const { groupID } = useParams();
  const navigate = useNavigate()

  useEffect(() => {
    POST<GetIndividualV2>(
      {
        ext: 'group/invite/public',
        query: {
          groupID,
        },
      },
      res => {
        if (res.statusCode === 200) {
          setCurrentUser(res.payload);
        }
        navigate(ROUTES.dashboard);
      }
    );
    localStorage.removeItem("groupInviteID"); // remove if a user is logged in
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupID]);

  return (
    <Flex
      alignItems={'center'}
      justifyContent={'center'}
      w={'100vw'}
      h={'100dvh'}
    >
      <Loader />
    </Flex>
  );
};

export default GroupInvite;
