import { type IIcon } from "./icon";


export const TitleIcon = ({mode}: IIcon) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
      <path d="M5.5 8C5.5 8.55228 5.94772 9 6.5 9H11H15C15.5523 9 16 8.55228 16 8V6.88889C16 6.33661 15.5523 5.88889 15 
      5.88889H6.5C5.94772 5.88889 5.5 6.33661 5.5 6.88889V8ZM4.33334 23C3.69167 23 3.14217 22.7604 2.68484 22.2813C2.2275
       21.8022 1.99922 21.227 2 20.5556V3.44445C2 2.77222 2.22867 2.19656 2.686 1.71745C3.14334 1.23834 3.69245 0.999187 4.33334 
       1H20.6667C21.3083 1 21.8578 1.23956 22.3152 1.71867C22.7725 2.19778 23.0008 2.77304 23 3.44445V20.5556C23 21.2278 22.7713
        21.8034 22.314 22.2826C21.8567 22.7617 21.3076 23.0008 20.6667 23H4.33334ZM4 20C4 20.5523 4.44772 21 5 21H12.5H20C20.5523 
        21 21 20.5523 21 20V12V4C21 3.44772 20.5523 3 20 3H12.5H5C4.44772 3 4 3.44772 4 4V12V20Z" fill={mode === 'light' ? "#040404" : "#F5F5F5"}/>
    </svg>
  );
};
