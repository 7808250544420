import { Flex, Button, Text, Image } from '@chakra-ui/react'
import { type FC } from 'react'

const ErrorPage: FC = () => {
  const refreshPage = () => {
    window.location.reload()
  }
  const previousPage = () => {
    window.location.href = "/auth/login";
  }

  return (
    <Flex
      flexDir={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      h={'100dvh'}
      w={'100%'}
      bg={'#111'}
    >
      <Flex
        pb={{ base: '2.5rem', md: '3.06rem' }}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Text
          fontSize={{ base: '3.375rem', md: '6rem' }}
          fontWeight={700}
          color={'#FFF'}
          fontStyle={'italic'}
        >
          4
        </Text>
        <Image
          src={'images/error.gif'}
          alt="0"
          w={{ base: '5.75rem', md: '11.5rem' }}
          h={{ base: '5.25rem', md: '10.375rem' }}
          mx={{ base: '0.38rem', md: '2rem' }}
        />
        <Text
          fontSize={{ base: '3.375rem', md: '6rem' }}
          fontWeight={700}
          color={'#FFF'}
          fontStyle={'italic'}
        >
          4
        </Text>
      </Flex>
      <Text
        fontSize={{ base: '1.75rem', md: '2rem' }}
        fontWeight={700}
        color={'#FFF'}
        pb={'1rem'}
      >
        Oops!
      </Text>
      <Text
        fontSize={{ base: '1rem', md: '1.125rem' }}
        fontWeight={500}
        color={'#FFF'}
        pb={{ base: '3rem', md: '3.69rem' }}
      >
        The page you are looking for can’t be found
      </Text>
      <Button
        // w={"15rem"}
        py={'0.81rem'}
        px={'4.7rem'}
        borderRadius={'1.87rem'}
        border={'1px solid #000'}
        bg={'#fff'}
        color={'#000'}
        fontSize={'1.125rem'}
        fontWeight={500}
        _hover={{ bg: '#c4c4c4' }}
        onClick={previousPage}
      >
        Go back
      </Button>
      <Button
        color={'#F5CE6F'}
        fontSize={'1.125rem'}
        fontWeight={500}
        cursor={'pointer'}
        bg={'transparent'}
        _hover={{ bg: 'transparent' }}
        onClick={refreshPage}
      >
        Retry
      </Button>
    </Flex>
  )
}
export default ErrorPage
