import { Box, useColorMode, useMediaQuery } from "@chakra-ui/react";
import { Topbar, Content, BottomNav } from "../../parts";
import { useLocation } from "react-router-dom";
import { type BodyInterface } from "./types";
import { useContext, type FC } from "react";
import { setViewContext } from "utils/store/set.state";
import { store } from "utils/effects";
import { CurrentUserContext } from "routes/AuthRouteProvider";
import { IndividualRole } from "routes/types";

const Body: FC<BodyInterface> = ({ openRoute, username }: BodyInterface) => {
  const { currentUser } = useContext(CurrentUserContext)

  const { pathname } = useLocation();
  const { colorMode } = useColorMode();

  const isEditor = pathname === "/editor";

  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const { viewContext, otherSections } = store.value;
  const isGroupIconClickable = !otherSections.find((section) => section.kind === "group_section");

  return (
    <Box w={"full"} h={"100dvh"}>
      <Topbar
        isEditor={isEditor}
        colorMode={colorMode}
        isWeb={isLargerThan768}
        username={username}
        view={viewContext}
        setView={setViewContext}
      />
      <Content isEditor={isEditor} view={viewContext} />
      <BottomNav
        avatar={currentUser.avatar}
        colorMode={colorMode}
        isEditor={isEditor && viewContext === 'Editor'}
        isWeb={isLargerThan768}
        openRoute={openRoute}
        shouldIncludeGroup={currentUser.role === IndividualRole.GROUP_ADMIN}
        isGroupIconClickable={isGroupIconClickable}
        isPlatform={!!currentUser.platformAccountID}
      />
    </Box>
  );
};

export default Body;
