import { type IIcons } from './icon.types'

export const PlatformsIcon = ({ mode, activeStatus }: IIcons) => {
  const color = {
    light: {
      active: '#040404',
      inactive: '#808080',
    },
    dark: {
      active: '#F5CE6F',
      inactive: '#F5F5F5',
    },
  }[mode][activeStatus]

  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width="18"
      height="18"
      viewBox="0 0 21 21">
      <path
        // eslint-disable-next-line max-len
        d="M26 23V4a3.003 3.003 0 0 0-3-3H9a3.003 3.003 0 0 0-3 3v19zm-15.923-5.385 5-12a1 1 0 0 1 1.846 0l5 12a1 1 0 0 1-1.548 1.166L16 15.28l-4.375 3.501a1 1 0 0 1-1.548-1.166z"
        fill={color}
      />
      <path
        // eslint-disable-next-line max-len
        d="M16 13a1.002 1.002 0 0 1 .625.219l1.95 1.56L16 8.6l-2.574 6.18 1.949-1.56A1.002 1.002 0 0 1 16 13zM6 25v3a3.003 3.003 0 0 0 3 3h14a3.003 3.003 0 0 0 3-3v-3zm11 4h-2a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2z"
        fill={color}
      />
    </svg>
  )
}
