import type { DropResult } from 'react-beautiful-dnd'
import type {
  SectionData,
  SectionDisplayStatusType,
  SectionKindType,
} from '../../pages/Editor/types'

import {
  deleteAllSectionsStore,
  deleteAllSectionsStoreProxy,
  deleteSingleSection,
  deleteSingleSectionStore,
  deleteSingleSectionStoreProxy,
  store,
  updateEditor,
  updateSectionsInStore,
} from './apollo.effects'
import { setSectionKind, unsetSectionKind } from 'utils/store/set.state'

export const onDragEnd = (result: DropResult): void => {
  const { source, destination } = result
  if (
    !destination ||
    (destination.droppableId === source.droppableId &&
      destination.index === source.index)
  ) {
    return
  }

  swap(source.index, destination.index)
}

const move = (sourceId: number, direction: 'up' | 'down') => {
  const destinationId = direction === 'up' ? sourceId - 1 : sourceId + 1
  swap(sourceId, destinationId)
}

const swap = (sourceId: number, destinationId: number) => {
  const { headerSection, otherSections } = store.value;

  const result = Array.from(otherSections);
  const [removed] = result.splice(sourceId, 1);
  result.splice(destinationId, 0, removed);

  saveSections([headerSection, ...result].filter(section => section));
}

export const handleMoveUp = (index: number) => {
  move(index, 'up')
}

export const handleMoveDown = (index: number) => {
  move(index, 'down')
}

export const handleEdit = (
  kind: SectionKindType,
  sectionData: SectionData,
  index: number,
) => {
  setSectionKind(kind, 'edit', sectionData, index)
}

export const handleDisplayStatus = (
  index: number,
  displayStatus: SectionDisplayStatusType,
) => {
  if (index === 0) return // don't hide header

  const { headerSection, otherSections, currentSectionIndex } = store.value
  const _sections = [headerSection, ...otherSections]
  const sections = _sections.map((section, _index) => {
    if (_index === index) {
      return { ...section, displayStatus }
    } else {
      return section
    }
  })

  saveSections(sections, currentSectionIndex)
}

export const handleDuplicate = (index: number) => {
  if (index === 0) return // don't hide header

  const { headerSection, otherSections } = store.value
  const _sections = [headerSection, ...otherSections]
  const { _id, ...clone } = _sections[index]
  const sections = [
    ..._sections.slice(0, index + 1),
    clone as SectionData,
    ..._sections.slice(index + 1),
  ]

  saveSections(sections)
}

export const handleDelete = (index: number) => {
  // console.log("clicked", {index});
  if (index === -1) return // invalid

  const section = store.value.otherSections[index];
  const sectionID = section._id;
  deleteSingleSection({sectionID}, index);
}

export const handleSave = (
  sectionData: SectionData,
  ctx: 'create' | 'edit',
) => {
  const { data, currentSectionIndex } = store.value
  const _sections = data.editor.sections as unknown as SectionData[]
  const sections =
    ctx === 'edit'
      ? _sections.map((section, _index) => {
          if (_index === currentSectionIndex) {
            return sectionData
          } else {
            return section
          }
        })
      : [..._sections, sectionData]

  saveSections(sections, currentSectionIndex)
  unsetSectionKind();
}

const saveSections = (_sections: SectionData[], currentSectionIndex?: number) => {
  const { data } = store.value
  const input = {
    peigeID: data.editor.peigeID,
    sections: _sections,
  }
  updateSectionsInStore({sections: input.sections});
  updateEditor(input, currentSectionIndex)
}

const deleteAllSectionsProxyHandler: ProxyHandler<(...args: any[]) => void> = {
  apply(target, _thisArg, argList) {
    ;(async () => {
      await (target as unknown as (...args: any[]) => Promise<void>)(...argList)
      deleteAllSectionsStore.value = {
        ...deleteAllSectionsStore.value,
        ...deleteAllSectionsStoreProxy,
      }
    })()
  },
}

export const showYesOrNoDeleteAllSectionsDialog = new Proxy(() => {
  deleteAllSectionsStoreProxy.shouldShowYesOrNoDeleteAllSectionsDialog = true
}, deleteAllSectionsProxyHandler)
export const hideYesOrNoDeleteAllSectionsDialog = new Proxy(() => {
  deleteAllSectionsStoreProxy.shouldShowYesOrNoDeleteAllSectionsDialog = false
}, deleteAllSectionsProxyHandler)
export const showUndoDeleteAllSectionsDialog = new Proxy(() => {
  deleteAllSectionsStoreProxy.shouldShowYesOrNoDeleteAllSectionsDialog = false
  deleteAllSectionsStoreProxy.shouldShowUndoDeleteAllSectionsDialog = true
  automaticallyHideUndoDeleteAllSectionsDialog()
}, deleteAllSectionsProxyHandler)
export const hideUndoDeleteAllSectionsDialog = new Proxy(() => {
  deleteAllSectionsStoreProxy.shouldShowUndoDeleteAllSectionsDialog = false
}, deleteAllSectionsProxyHandler)

const automaticallyHideUndoDeleteAllSectionsDialog = () => {
  setTimeout(hideUndoDeleteAllSectionsDialog, 4000)
}

const deleteSingleSectionStoreProxyHandler: ProxyHandler<(...args: any[]) => void> = {
  apply(target, _thisArg, argList) {
    ;(async () => {
      await (target as unknown as (...args: any[]) => Promise<void>)(...argList)
      deleteSingleSectionStore.value = {
        ...deleteSingleSectionStore.value,
        ...deleteSingleSectionStoreProxy,
      }
    })()
  },
}

export const showUndoDeleteSingleSectionDialog = new Proxy((sectionIndex: number) => {
  deleteSingleSectionStoreProxy.shouldShowUndoDeleteSingleSectionDialog = true
  deleteSingleSectionStoreProxy.sectionIndex = sectionIndex
  automaticallyHideUndoDeleteSingleSectionDialog()
}, deleteSingleSectionStoreProxyHandler)
export const hideUndoDeleteSingleSectionDialog = new Proxy(() => {
  deleteSingleSectionStoreProxy.shouldShowUndoDeleteSingleSectionDialog = false
  deleteSingleSectionStoreProxy.sectionIndex = -1
}, deleteSingleSectionStoreProxyHandler)
const automaticallyHideUndoDeleteSingleSectionDialog = () => {
  setTimeout(hideUndoDeleteSingleSectionDialog, 5000)
}
