import { gql } from '@apollo/client';

export const PAGE_VIEWS_QUERY = gql`
  query PageViews {
    pageView @rest(type: "PageViews", path: "/metrics/peige-view") {
      statusCode
      message
      payload {
        lastWeekData {
            key
            views
            date
            day
            month
        }
        lastThirtyDaysData {
            key
            views
            date
            day
            month
        }
        lastYearData {
            key
            views
            date
            day
            month
        }
      }
    }
  }
`;