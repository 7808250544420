
// https://stackoverflow.com/questions/12043187/how-to-check-if-hex-color-is-too-black

export const fastLightOrDark = (color: string) => {
  const hex = color.replace('#', '');
  const cR = parseInt(hex.substring(0, 2), 16);
  const cG = parseInt(hex.substring(2, 4), 16);
  const cB = parseInt(hex.substring(4, 6), 16);

  const brightness = ((cR * 299) + (cG * 587) + (cB * 114)) / 1000;
  return brightness > 155 ? "#000000" : "#ffffff";
}

export const getHexFromLinearGradient = (color: string) => color.substring(15, 22);

export const getTextColor = (backgroundColor: string) => {
  const textColor = backgroundColor.startsWith('linear') ? getHexFromLinearGradient(backgroundColor) : backgroundColor
  return fastLightOrDark(textColor)
};

export const getRGBFromHex = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) return `rgba(0,0,0,0.25)`;
  return `rgba(${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)},0.5)`;
};
