/* eslint-disable @typescript-eslint/consistent-type-definitions */
/* eslint-disable @typescript-eslint/array-type */
import {
  Box,
  useColorModeValue,
  Flex,
  Icon,
  Text,
  Skeleton,
  SimpleGrid,
} from '@chakra-ui/react'

import { Button } from 'components'
import { IoIosArrowBack } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import theme from 'theme'
import { useEffect, useState } from 'react'
import { GET } from 'utils/base.api'
import { formatNumber } from 'utils/fomat.number'

type HeaderFormData = {
  headerFormCount: number;
  headerFormEntries: {
    field1Value: string;
    field2Value: string;
  }[];
  field1Name: string;
  field2Name: string;
};

const FormAnalytics = () => {
  const navigate = useNavigate()

  const darkBg = theme.colors.shareModal.dark.iconBg
  const lightBg = theme.colors.text.primary.default

  const boxBg = useColorModeValue(lightBg, darkBg)
  const iconFilter = useColorModeValue('invert(1)', 'invert(0)')

  const [ headerFormData, setHeaderFormData] = useState<HeaderFormData>(
    {
      headerFormCount: 0,
      headerFormEntries: [],
      field1Name: "",
      field2Name: "",
    }
  );

  const [ loading, setLoading ] = useState(true);

  const mails = headerFormData.headerFormEntries.map(
    ({field1Value, field2Value}, index) => ({no: index + 1, field1Value, field2Value})
  );

  useEffect(() => {
    GET<HeaderFormData>(
      {
        ext: "header-form-entry/all"
      }, 
    (res) => {
      try {
        if (res.statusCode === 200) {
          setHeaderFormData(res.payload);
        }
      }finally {
        setLoading(false);
      }
    });
  }, []);

  const convertToCSV = (data: HeaderFormData) => {
    const csvRows = [];
  
    const headers = ['No', data.field1Name, data.field2Name];
    csvRows.push(headers.join(','));
  
    data.headerFormEntries.forEach((entry, index) => {
      const values = [index + 1, entry.field1Value, entry.field2Value];
      csvRows.push(values.join(','));
    });
  
    return csvRows.join('\n');
  };

  const downloadCSV = (csv: string, filename: string) => {
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleDownload = () => {
    const csv = convertToCSV(headerFormData);
    downloadCSV(csv, 'form_submissions.csv');
  };


  return (
    loading ?
    <Flex
      m="0 auto"
      flexDir={{ base: 'column', md: 'row' }}
      borderRadius={'25px'}
      gap={5}
      w={'full'}
      p={{ base: '1rem', md: '2rem' }}
    >
      <Skeleton
        m="0 auto"
        borderRadius={'25px'}
        bg={boxBg}
        alignItems={'center'}
        placeItems={'center'}
        maxW={'920px'}
        w={{ base: 'full', md: '30%' }}
        p={{ base: '1rem', md: '2rem' }}
      />
      <Skeleton
        w={{ base: 'full', md: '70%' }}
        h={'546px'}
        bg={boxBg}
        p={{ base: '1rem', md: '2rem' }}
        borderRadius={'32px'}
        flexDir={'column'}
        fontFamily={'dm sans'}
        overflow={'hidden'}
      />
    </Flex> :
    <Flex
      m="0 auto"
      flexDir={{ base: 'column', md: 'row' }}
      borderRadius={'25px'}
      gap={5}
      w={'full'}
      p={{ base: '1rem', md: '2rem' }}
    >
      <Box
        m="0 auto"
        borderRadius={'25px'}
        bg={boxBg}
        alignItems={'center'}
        placeItems={'center'}
        maxW={'920px'}
        w={{ base: 'full', md: '30%' }}
        p={{ base: '1rem', md: '2rem' }}
      >
        <Flex
          gap="5px"
          alignItems={'center'}
          cursor="pointer"
          onClick={() => {
            navigate('/dashboard')
          }}
        >
          <Icon
            filter={iconFilter}
            as={IoIosArrowBack}
            color="#fff"
            w={6}
            h={6}
          />
          <Text fontSize={{ base: '14px', md: '18px' }} fontWeight={500}>
            {"Dashboard"}
          </Text>
        </Flex>
        <Flex
          flexDir={'column'}
          textAlign={'center'}
          justify={'center'}
          gap={'2px'}
          h={'calc(100% - 60px)'}
          mt={{ base: '4', md: '0' }}
          placeContent={'center'}
          placeItems={'center'}
        >
          <Text fontWeight={700} fontSize={{ base: '32px', md: '64px' }}>
            {formatNumber(headerFormData.headerFormCount)}
          </Text>
          <Text fontWeight={600} fontSize={{ base: '16px', md: '20px' }}>
            Form Submissions
          </Text>
        </Flex>
      </Box>

      <Flex
        w={{ base: 'full', md: '70%' }}
        h={'546px'}
        bg={boxBg}
        p={{ base: '1rem', md: '2rem' }}
        borderRadius={'32px'}
        flexDir={'column'}
        fontFamily={'dm sans'}
        overflow={'hidden'}
      >
        <Flex justifyContent={'space-between'} w={'100%'} pb={'39px'}>
          <Text
            fontSize={{ base: '20px', md: '28px' }}
            lineHeight={'36.46px'}
            fontWeight={500}
          >
            Submissions
          </Text>
          <Button
            text={'Download'}
            borderRadius={'46.51px'}
            padding={'11px 15px'}
            fontSize={'14px'}
            height={'41px'}
            onClick={handleDownload}
          />
        </Flex>

        <Box>
          <SimpleGrid columns={2} spacingX="86px" px="30px" pb="7px">
            <Text
              fontSize="16px"
              lineHeight="20.83px"
              opacity="50%"
              whiteSpace="nowrap"
            >
              {headerFormData.field1Name}
            </Text>
            <Text
              fontSize="16px"
              lineHeight="20.83px"
              opacity="50%"
              whiteSpace="nowrap"
            >
              {headerFormData.field2Name}
            </Text>
          </SimpleGrid>
          <Box
            overflow="auto"
            className="no-scrollbar"
            height="400px"
            sx={{
              mask: `linear-gradient(
            to bottom,
            rgba(0, 0, 0, 1) 0,
            rgba(0, 0, 0, 1) 75%,
            rgba(0, 0, 0, 0) 95%,
            rgba(0, 0, 0, 0) 0
          )
          100% 50% / 100% 100% repeat-x `,
            }}
          >
            {headerFormData.headerFormEntries.map(
    ({field1Value, field2Value}, index) => ({no: index + 1, field1Value, field2Value})
  ).map((info, i) => {
              const { no, field1Value, field2Value } = info
              return (
                <SimpleGrid
                  key={no}
                  columns={2}
                  spacingX="86px"
                  pb={i === mails.length - 1 ? '70px' : ''}
                >
                  <Flex>
                    <Text
                      pl="15px"
                      mr="3px"
                      fontSize="16px"
                      whiteSpace="nowrap"
                      lineHeight="48px"
                    >
                      {no}.
                    </Text>
                    <Text
                      fontSize="16px"
                      pl="3px"
                      lineHeight="48px"
                      whiteSpace="nowrap"
                    >
                      {field1Value}
                    </Text>
                  </Flex>
                  <Text fontSize="16px" lineHeight="48px" whiteSpace="nowrap">
                    {field2Value}
                  </Text>
                </SimpleGrid>
              )
            })}
          </Box>
        </Box>
      </Flex>
    </Flex>
  )
}

export default FormAnalytics
