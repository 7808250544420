export default (color: string): string => {
  const rgbaMatch = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)$/i);
  if (!rgbaMatch) {
    return "#000000";
  }

  let r = parseInt(rgbaMatch[1], 10);
  let g = parseInt(rgbaMatch[2], 10);
  let b = parseInt(rgbaMatch[3], 10);
  let a = rgbaMatch[4] ? parseFloat(rgbaMatch[4]) : undefined;

  // Ensure values are within valid range
  r = Math.max(0, Math.min(255, r));
  g = Math.max(0, Math.min(255, g));
  b = Math.max(0, Math.min(255, b));

  // Convert RGB to hexadecimal
  let hex = ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);

  // Add alpha if provided
  if (a !== undefined) {
      // Ensure alpha value is within valid range
      a = Math.max(0, Math.min(1, a));
      // Convert alpha to hexadecimal and add to RGB hex
      hex += Math.round(a * 255).toString(16).padStart(2, '0');
  }

  return '#' + hex;
}
