/* eslint-disable no-useless-escape */
import * as yup from 'yup'

export const AUTHVALIDATIONS = {
  email: yup
    .string()
    .required('Email required!')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Invalid email!',
    ),
  password: yup
    .string()
    .min(8, 'Minimum of 8 characters')
    .required('Password is required!'),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
  // ),
  confirmpassword: yup
    .string()
    .required('Confirm password is required!')
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value || this.parent.newPassword === value
    }),
  username: yup
    .string()
    .required('Username is required!')
    .matches(/(?!.*[\.\-\_]{2,})^[a-zA-Z0-9\.\-\_]{3,24}$/, 'Invalid username'),
  firstname: yup
    .string()
    .required('First name is required')
    .matches(/(?!.*[\.\-\_]{2,})^[a-zA-Z0-9\.\-\_]{3,24}$/, 'Invalid name'),
  organisationName: yup.string().required('Required'),
  lastname: yup
    .string()
    .required('Last name is required')
    .matches(/(?!.*[\.\-\_]{2,})^[a-zA-Z0-9\.\-\_]{3,24}$/, 'Invalid name'),
  fullName: yup.string().required('Full name is required'),
  dob: yup.date().required('DOB is Required'),
  country: yup.string().required('Country is required'),
  applicableTitles: yup
    .array()
    .of(yup.string().required('Applicable titles are required')),
  avatar: yup.mixed().required('Avatar is required'),
  // .test(
  //   "fileFormat",
  //   "Unsupported Format",
  //   (value: any) => value && SUPPORTED_FORMATS.includes(value?.type)
  // ),
  image: yup.string().required('Image is required'),
  name: yup.string().required('Name is required'),
  actual_price: yup
    .number()
    .min(1, 'Minimum actual price should be 1')
    .required('Actual Price is required'),
  price: yup.number().optional().min(1, 'Minimum price should be 1'),
  description: yup.string().required('Description is required'),
  bio: yup.string().required('Bio is required'),
  otp: yup
    .string()
    .required('Code is required')
    .matches(/^\d+$/, 'Only digit is allowed')
    .test(
      'length',
      'Provide 6 digits code',
      (val: any) => val && val.length === 6,
    ),
  title: yup.string().required('Title is required'),
  category: yup.string().required('Category is required'),
}
