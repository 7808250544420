import { Flex } from '@chakra-ui/react'
import { Loader } from 'components'
import { type FC } from 'react'

const PageLoader: FC = () => {
  return (
    <Flex w={'full'} h={"100vh"} alignItems={'center'}>
      <Loader />
    </Flex>
  )
}

export default PageLoader
