import { type IIcon } from "./icon";


export const LinksIcon = ({mode}: IIcon) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 25 24" fill="none">
      <path d="M18.864 15.5352L17.45 14.1212L18.864 12.7072C19.7821 11.7657 20.2923 10.5003 20.2841 9.1852C20.2758 7.87012 19.7498
       6.61124 18.82 5.68125C17.8901 4.75126 16.6313 4.22506 15.3162 4.21664C14.0011 4.20821 12.7357 4.71824 11.794 5.63623L10.378 
       7.05023L8.96499 5.63623L10.379 4.22223C11.6918 2.90941 13.4724 2.17188 15.329 2.17188C17.1856 2.17188 18.9662 2.90941 20.279
       4.22223C21.5918 5.53505 22.3293 7.31562 22.3293 9.17223C22.3293 11.0288 21.5918 12.8094 20.279 14.1222L18.864 15.5352ZM16.036 
       18.3642L14.622 19.7782C13.9719 20.4283 13.2002 20.9439 12.3509 21.2957C11.5016 21.6475 10.5913 21.8286 9.67199 21.8286C8.75269 
       21.8286 7.84239 21.6475 6.99307 21.2957C6.14374 20.9439 5.37203 20.4283 4.72199 19.7782C4.07194 19.1282 3.5563 18.3565 3.2045 
       17.5072C2.8527 16.6578 2.67163 15.7475 2.67163 14.8282C2.67163 13.9089 2.8527 12.9986 3.2045 12.1493C3.5563 11.3 4.07194 10.5283
        4.72199 9.87823L6.13599 8.46423L7.55099 9.87823L6.13599 11.2932C5.21799 12.2349 4.70797 13.5004 4.71639 14.8155C4.72482 16.1305
         5.25102 17.3894 6.18101 18.3192C7.11099 19.2491 8.36988 19.7751 9.68496 19.7833C11 19.7916 12.2654 19.2814 13.207 18.3632L14.622
         16.9502L16.036 18.3642ZM15.329 7.75723L16.743 9.17123L9.67199 16.2432L8.25799 14.8282L15.328 7.75823L15.329 7.75723Z" 
         fill={mode === 'light' ? "#040404" : "#F5F5F5"}/>
    </svg>
  );
};

