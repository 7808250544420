import { gql } from '@apollo/client';

export const SEND_OTP_MUTATION = gql`
  fragment EmailOtp on REST {
    email: String
  }

  mutation SendOtp($input: EmailOtp!) {
    send(input: $input) @rest(type: "SendOtp", method: "POST", path: "/auth/send-otp-individual") {
      statusCode
      message
      payload {
        email
      }
    }
  }
`;