import { Flex } from "@chakra-ui/react";
import { Menu } from "Lib";
import { NavIcon, NavIconButton } from "components";
import { type NavInterface } from "./types";
import { type FC } from "react";

const Nav: FC<NavInterface> = ({ openRoute, order, shouldIncludeGroup, isPlatform }: NavInterface) => {
  return (
    <Flex
      flexDir={order === "column" ? "column" : "row"}
      justify="space-between"
      align={"center"}
      p={
        order === "column"
          ? { base: "2px 0", sm: "10px 0", md: "12px 0" }
          : { base: "0 2px", sm: "0 10px", md: "0 12px" }
      }
      pos={"relative"}
      gap={order === "column" ? 8 : 0}
    >
      {
        Menu
          .LinkItems
          .filter((link) => {
            return shouldIncludeGroup ? true : link.name !== "Group"
          })
          .map((link) => (
            <NavIconButton
              key={link.name}
              active={link.active}
              flexDir={"column"}
              open={() => { openRoute(link.route); }}
              icon={<NavIcon navname={link.name} />}
              iconLabel={link.name}
            />
          ))
      }
    </Flex>
  );
};

export default Nav;
