import { gql } from '@apollo/client'
import { EditorResponse } from './editorResponse'

export const UPDATE_STYLE_SETTINGS = gql`
  fragment SettingsInput on REST {
    backgroundMode: String
    backgroundColor: String
    isGradient: Boolean
    backgroundImage: String
    backgroundImagePosition: String
    font: String
    linksTextColor: String
    linksBackgroundColor: String
    linksShape: String
    linksButtionShouldShow: Boolean
    linksTextAlignment: String
  }

  fragment UpdateSettingsInput on REST {
    settings: SettingsInput
  }

  mutation UpdateStyleSettings($input: UpdateSettingsInput!) {
    updateStyleSettings(input: $input)
      @rest(type: "UpdateStyleSettings", method: "PUT", path: "/peige/settings/graphql") {
        ${EditorResponse}
    }
  }
`
