import { useMutation } from '@apollo/client'
import {
  Box,
  Link as Anchor,
  Stack,
  Text,
  useColorMode,
  useToast,
  Flex,
  Heading,
} from '@chakra-ui/react'
import { RESET_PASSWORD_MUTATION } from 'apolloClient/mutations/resetPassword'
import { SEND_OTP_MUTATION } from 'apolloClient/mutations/sendOTP'
import { Button, PasswordInput, PinInput, FormikFormInput, } from 'components'
import { FormikProvider, useFormik } from 'formik'
import { type FC, useState } from 'react'
import { ROUTES } from 'routes/links'
import { useNavigate } from 'react-router-dom'
import { sendOTPValidator } from 'validators'
import theme from 'theme'

const PasswordReset: FC = () => {
  const { colorMode } = useColorMode()
  const navigate = useNavigate()
  const toast = useToast({
    position: 'top',
    containerStyle: {
      zIndex: 9,
    },
  })

  const firstStep = localStorage.getItem('step') ?? '1'
  const currentEmail = localStorage.getItem('email') ?? ''

  const [step, setStep] = useState(firstStep)

  const [userEmail, setUserEmail] = useState(currentEmail)

  const resetPasswordValues = { email: currentEmail ?? '', type: 'reset' }

  const [sendOtpMutation, { loading: sendingOtp }] =
    useMutation(SEND_OTP_MUTATION)

  const [resetPasswordMutation, { loading: resettingPassword }] = useMutation(
    RESET_PASSWORD_MUTATION,
  )

  const sendOtpToEmail = useFormik({
    initialValues: resetPasswordValues,
    validationSchema: sendOTPValidator,
    onSubmit: values => {
      setUserEmail(values.email)
      const input = { email: values.email }
      sendOtpMutation({
        variables: { input },
        onCompleted: data => {
          const { payload } = data.send
          localStorage.setItem('email', payload.email)
          setStep('2')
        },
        onError: () => {
          toast({
            status: 'error',
            description: 'email or password incorrect',
          })
        },
      })
    },
  })

  const [otpValue, setOtpValue] = useState('')

  const handleChange = (value: string): void => {
    setOtpValue(value)
  }

  const resetPasswordPayload = { newPassword: '', otp: '' }

  const resetPassword = useFormik({
    initialValues: { ...resetPasswordPayload },
    // validationSchema: resetPasswordValidator,

    onSubmit: values => {
      const userEmailValue = localStorage.getItem('email')
      if (!userEmail && userEmailValue) {
        setUserEmail(userEmailValue)
      }
      const input = { ...values, otp: otpValue, email: userEmail }
      resetPasswordMutation({
        variables: { input },
        onCompleted: () => {
          toast({
            status: 'success',
            description: 'Password reset successful',
          })
          setStep('1')
          navigate(ROUTES.login)
        },
        onError: () => {
          toast({
            status: 'error',
            description: 'email or password incorrect',
          })
        },
      })
    },
  })

  return (
    <Stack spacing={4}>
      <FormikProvider value={step === '1' ? sendOtpToEmail : resetPassword}>
        <form
          noValidate
          onSubmit={
            step === '1'
              ? sendOtpToEmail.handleSubmit
              : resetPassword.handleSubmit
          }
          style={{ width: '100%' }}
        >
          {step === '1' && (
            <Flex flexDir={'column'}>
              <Stack align={'center'} mb={4}>
                <Heading fontSize={'4xl'} textAlign={'center'}>
                  {'Forgot Password'}
                </Heading>
                <Anchor
                  href={'/auth/login'}
                  color={
                    colorMode === 'light'
                      ? theme.colors.button.bg.primary
                      : theme.colors.button.bg.secondary
                  }
                >
                  {'Remember Password? Sign in'}
                </Anchor>                
              </Stack>
              <Box width={{ base: '100%' }}>
                <FormikFormInput
                  placeholder="email address"
                  name="email"
                  type="email"
                  value={resetPasswordValues.email}
                  disabled={false}
                  showMessage={true}
                />
              </Box>
            </Flex>
          )}
          {step === '2' && (
            <Flex flexDir={'column'}>
              <Stack align={'center'} mb={4}>
                <Heading fontSize={'4xl'} textAlign={'center'}>
                  {'Reset Password'}
                </Heading>
                <Text
                  align={'center'}
                  fontSize={'lg'}
                  color={
                    colorMode === 'light'
                      ? theme.colors.background.primary._dark
                      : theme.colors.background.primary.default
                  }
                  opacity={0.6}
                >
                  {'We sent a code to the email provided'}
                </Text>
              </Stack>
              <Box width={{ base: '100%' }}>
                <PasswordInput
                  placeholder="new password"
                  name="newPassword"
                  value={resetPasswordPayload.newPassword}
                  disabled={false}
                  showMessage={true}
                />
              </Box>
              <Box width={{ base: '100%' }}>
                <PinInput
                  value={otpValue}
                  onChange={handleChange}
                  showMessage={true}
                />
              </Box>
            </Flex>
          )}
          <Box width={'full'} mt={2}>
            <Flex justify={'space-between'} gap={'11px'}>
              {step === '2' && (
                <Button
                  text={'Back'}
                  w={'full'}
                  h={'50px'}
                  borderRadius={'8px'}
                  maxW={'full'}
                  fontWeight={'500'}
                  fontSize={'16px'}
                  onClick={() => {
                    setStep('1')
                  }}
                />
              )}
              <Button
                text={step === '1' ? 'Send OTP' : 'Submit'}
                isLoading={step === '1' ? sendingOtp : resettingPassword}
                w={'full'}
                h={'50px'}
                borderRadius={'8px'}
                maxW={'full'}
                fontWeight={'500'}
                fontSize={'16px'}
              />
            </Flex>
          </Box>
        </form>
      </FormikProvider>
      <Stack pt={6}>
        <Text align={'center'}>
          I don&apos;t have an account?{' '}
          <Anchor
            href={'/auth/register'}
            color={
              colorMode === 'light'
                ? theme.colors.button.bg.primary
                : theme.colors.button.bg.secondary
            }
          >
            Signup
          </Anchor>
        </Text>
      </Stack>
    </Stack>
  )
}

export default PasswordReset
