import { gql } from '@apollo/client'

export const LOGIN_MUTATION = gql`
  fragment LoginInput on REST {
    username: String
    password: String
  }

  mutation UserLogin($input: LoginInput!) {
    login(input: $input)
      @rest(
        type: "UserLogin"
        method: "POST"
        path: "/auth/signin-individual"
      ) {
      message
      token
      payload {
        _id
        username
      }
    }
  }
`
