import {
  Flex,
  Icon,
  Text,
  useColorModeValue,
  useColorMode,
} from '@chakra-ui/react'
import { type FC } from 'react'
import { AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai'
import theme from 'theme'
import { type AnalyticsCardProps } from 'typings/interface'
import { formatNumber } from 'utils/fomat.number'

const AnalyticsCard: FC<AnalyticsCardProps> = ({
  name,
  value,
  icon,
  percentage,
  percentageType,
  onClick,
}: AnalyticsCardProps) => {
  const { colorMode } = useColorMode()
  const darkSecondaryBg = theme.colors.background.secondary._dark
  const lightSecondaryBg = theme.colors.background.secondary.default
  return (
    <Flex
      flexDir={'column'}
      w={'full'}
      minW={{ base: '240px', sm: '320px' }}
      maxW={'332px'}
      h={{ base: '190px', md: '220px' }}
      p={{ base: '24px', md: '40px 40px 30px' }}
      bg={colorMode === 'light' ? lightSecondaryBg : darkSecondaryBg}
      borderRadius={'25px'}
      cursor="pointer"
      onClick={onClick}
    >
      <Flex w={'full'} flexDir={'column'} align={'flex-start'}>
        <Flex alignItems={'center'}>
          {/* <Image
            src={icon}
            w={"22px"}
            h={"22px"}
            filter={useColorModeValue("invert(1)", "invert(0)")}
            alt={name}
            loading={"lazy"}
          /> */}
          <Icon
            filter={useColorModeValue('invert(1)', 'invert(0)')}
            as={icon}
            color="#fff"
            w={8}
            h={8}
          />
          <Text ml={'15px'}>{name}</Text>
        </Flex>
        <Text fontSize={'50px'} fontWeight={800}>
          {formatNumber(value)}
        </Text>
      </Flex>
      {percentageType && (
        <Flex w="full" flexDir={'column'} gap="5px" alignItems={'flex-end'}>
          <Flex alignItems={'center'} gap="5px">
            <Icon
              color={
                percentage && percentageType === 'INCREASE'
                  ? '#4A8F51'
                  : '#D13D4E'
              }
              as={
                percentage && percentageType === 'INCREASE'
                  ? AiOutlineArrowUp
                  : AiOutlineArrowDown
              }
              w={6}
              h={6}
            />
            <Text
              fontWeight={500}
              fontSize={'16px'}
              color={
                percentage && percentageType === 'INCREASE'
                  ? '#4A8F51'
                  : '#D13D4E'
              }
            >
              {percentage ?? '0'} %
            </Text>
          </Flex>
          <Text fontWeight={500} fontSize={'16px'}>
            vs yesterday
          </Text>
        </Flex>
      )}
    </Flex>
  )
}

export default AnalyticsCard
