
import { GET } from "utils/base.api";
import { useEffect, useState } from "react";
import { type GetIndividualV2 } from "routes/types";
import { CurrentUserStore, type CurrentUserData } from "routes/AuthRouteProvider";


// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type FetchCurrentUserParams = {
  onComplete: (CurrentUserData: CurrentUserData & {loading: boolean}) => void;
  onError: () => void;
};
const fetchCurrentUser = ({onComplete, onError}: FetchCurrentUserParams) => {
  if (!CurrentUserStore.value.loading) return CurrentUserStore.value;
  GET<GetIndividualV2>({
    ext: "individual/v2",
  }, (res) => {
    if (res.statusCode === 200) {
      const {onboardingProfile } = res.payload;
      const CurrentUserData = {
        loading: false,
        currentUser: res.payload,
        profile: onboardingProfile,
      };
      CurrentUserStore.value = CurrentUserData;
      onComplete(CurrentUserData);
    }else {
      onError();
    }
  });
};

export const updateCurrentUser = (currentUser: GetIndividualV2) => {
  const { onboardingProfile } = currentUser;
  const CurrentUserData = {
    loading: false,
    currentUser,
    profile: onboardingProfile,
  };
  CurrentUserStore.value = CurrentUserData;
};

export const useCurrentUser = () => {
  const [error, setError] = useState(false); 
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<CurrentUserData & {loading: boolean}>(CurrentUserStore.value);

  useEffect(() => {
    fetchCurrentUser(
      {
        onComplete(currentUserData){
          setLoading(false);
          setData(currentUserData)
        },
        onError(){
          setError(true);
          setLoading(false);
        },
      },
    );
  }, []);

  return { data, error, loading, setData };
};
