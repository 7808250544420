import { gql } from '@apollo/client';
import { USER_QUERY_FRAGMENT } from 'apolloClient/fragments/userFragment';

export const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    currentUser @rest(type: "CurrentUser", path: "/individual/v2") {
      ...CurrentUserFragmment
    }

    getProfile @rest(type: "GetProfile", path: "/onboarding/profile") {
      payload {
        isBusiness
        profession
        interests
        birthday
        gender
      }
    }
  }

  ${USER_QUERY_FRAGMENT}
`;