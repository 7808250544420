import {
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useMediaQuery,
  useToast,
  useColorMode,
} from '@chakra-ui/react'
import SocialIcon from 'components/SocialIcon'
import { updateCurrentUser } from 'hooks/useCurrentUser'
import { type FC } from 'react'
import { GoArrowUpRight } from 'react-icons/go'
import { type GetIndividualV2 } from 'routes/types'
import theme from 'theme'
import { POST } from 'utils/base.api'



const darkLinkBg = theme.colors.shareModal.dark.linkBg
const darkText = theme.colors.shareModal.darkText
const lightText = theme.colors.shareModal.lightText
const lightIconBg = theme.colors.shareModal.light.iconBg

interface IShareTemplateModal {
  username: string
  // maxWidth: any
}


enum SharePeigeMode {
  WHATSAPP = "whatsapp",
  LINKEDIN = "linkedin",
  TWITTER = "twitter",
  LINK_COPY = "link_copy",
};

const sharePeigeMetrics = (sharePeigeMode: SharePeigeMode) => {
  POST<GetIndividualV2>(
    {
      ext: 'metrics/share-peige',
      body: { sharePeigeMode },
    },(res) => {
      if (res.statusCode === 201) {
        updateCurrentUser(res.payload);
      }
    }
  );
};

const ShareModal: FC<IShareTemplateModal> = ({
  username
}: IShareTemplateModal) => {
  const toast = useToast({
    position: 'top',
    containerStyle: {
      zIndex: 9,
    },
  })

  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')

  const shareToWhatsapp = (url: string, title: string): void => {
    const encodedUrl = encodeURIComponent(url + ' \n\n' + title)
    const whatsappMobileSharer = `whatsapp://send?text=${encodedUrl}`
    const whatsappWebSharer = `https://web.whatsapp.com/send?text=${encodedUrl}`

    isLargerThan768
      ? window.open(whatsappWebSharer, '_blank')
      : window.open(whatsappMobileSharer, '_blank')
    sharePeigeMetrics(SharePeigeMode.WHATSAPP);
  }

  const shareToTwitter = (url: string, title: string): void => {
    const encodedUrl = encodeURIComponent(url)
    const encodedTitle = encodeURIComponent(title)
    const twitterSharer = `https://www.twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedUrl}`

    window.open(twitterSharer, '_blank')
    sharePeigeMetrics(SharePeigeMode.TWITTER);
  }

  const shareToLinkedin = (url: string, title: string): void => {
    const linkedinSharer = `https://www.linkedin.com/shareArticle?mini=true&url=https://${url}&title=undefined&summary=undefined&source=LinkedIn`

    window.open(linkedinSharer, '_blank')
    sharePeigeMetrics(SharePeigeMode.LINKEDIN);
  }

  const copyUrl = async (url: string): Promise<void> => {
    try {
      await navigator.clipboard.writeText(url)
      toast({
        status: 'success',
        description: 'Copied to clipboard',
      })
      sharePeigeMetrics(SharePeigeMode.LINK_COPY);
    } catch (err) {
      console.error(err)
    }
  }
  const { colorMode } = useColorMode()

  const shareSocials = [
    { name: 'Whatsapp', icon: '/images/whatsapp.svg', fnc: shareToWhatsapp },
    { name: 'LinkedIn', icon: '/svgs/LinkedIn.svg', fnc: shareToLinkedin },
    { name: 'Twitter', icon: '/images/twitter.svg', fnc: shareToTwitter },
  ]

  const promiseClick = (): void => {
    copyUrl(`${process.env.REACT_APP_PUBLIC_URL}/${username}`)
  }

  return (
    <Flex
      flexDir={'column'}
      alignItems={'center'}
      justifyContent={'center'}
      fontFamily={'DM Sans, sans-serif'}
      pt={'0.5rem'}
      pb={'1.3rem'}
      // maxW={{base:'26.75rem', lg:'30rem'}}
      maxH={{ base: '20.375rem', md: '21.25rem' }}
    >
      <Text fontSize="1rem" fontWeight={700} mb={'2rem'}>
        Share your peige
      </Text>
      <Flex gap={'1.25rem'}>
        {shareSocials.splice(0, 3).map((social, index) => (
          <SocialIcon
            key={index}
            name={social.name}
            icon={social.icon}
            colorMode={colorMode}
            shareAction={() => {
              social.fnc(
                `${process.env.REACT_APP_PUBLIC_URL}/${username}`,
                'Share Page',
              )
            }}
          />
        ))}
      </Flex>
      <Flex
        w={'100%'}
        flexDir={'column'}
        mt={'1.88rem'}
        gap={'0.62rem'}
        justifyContent={'center'}
      >
        <Flex
          bgColor={colorMode === 'light' ? lightIconBg : darkLinkBg}
          py="0.75rem"
          pr={'1.38rem'}
          pl={{ base: '1rem', md: '1.12rem' }}
          borderRadius="0.5rem"
          maxWidth={{ base: '23rem', md: '25rem' }}
          h={'3rem'}
          w="100%"
          justifyContent={'space-between'}
          alignItems={'center'}
          m={'auto'}
          onClick={promiseClick}
          cursor="pointer"
        >
          <Text
            color={colorMode === 'light' ? darkText : lightText}
            fontSize={'1rem'}
          >
            Copy peige link
          </Text>
          <Image
            src={
              colorMode === 'light'
                ? '/images/clipboard2.svg'
                : '/images/clipboard.svg'
            }
            w={'1.5rem'}
            h={'1.5rem'}
            alt={'copy link'}
            loading={'lazy'}
          />
        </Flex>
        <Link
          href={`${process.env.REACT_APP_PUBLIC_URL}/${username}`}
          isExternal
        >
          <Flex
            bgColor={colorMode === 'light' ? lightIconBg : darkLinkBg}
            py="0.75rem"
            pr={'1.38rem'}
            pl={{ base: '1rem', lg: '1.12rem' }}
            borderRadius="0.5rem"
            maxWidth={{ base: '23rem', md: '25rem' }}
            h={'3rem'}
            w="100%"
            justifyContent={'space-between'}
            alignItems={'center'}
            m={'auto'}
          >
            <Text
              color={colorMode === 'light' ? darkText : lightText}
              fontSize={'16px'}
            >
              Visit Peige
            </Text>

            <Icon
              as={GoArrowUpRight}
              color={colorMode === 'light' ? darkText : lightText}
              w={'1.5rem'}
              h={'1.5rem'}
              cursor="pointer"
            />
          </Flex>
        </Link>
      </Flex>
    </Flex>
  )
}

export default ShareModal
