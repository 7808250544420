import { type SectionKindType, type SectionData } from 'pages/Editor/types'
import { getTextColor } from 'utils/color.checker'
import convertRGBAToHex from 'utils/convertRGBAToHex'

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type EditorCollection = {
  sectionKind?: SectionKindType
  ctx?: 'create' | 'edit'
  isEditorOpen: boolean
  isStylesModalOpen: boolean
  currentSectionIndex: number
  currentSectionData: SectionData
  data: {
    editor: {
      peigeID: string
      sections: SectionData[]
      settings: PeigeSettings
    }
  }
  headerSection: SectionData
  otherSections: SectionData[]
  viewContext: ViewContext
  mockBGColor: string
  mockFont: string
  mockBGOverlay: BackgroundImageOverlay
  mockBGPosition: BackgroundImagePosition
  mockBGEffect: BackgroundImageEffect
  mockBGMode: BackgroundMode
  mockLinksTextColor: string
  mockLinksBackgroundColor: string
  mockLinksShape: LinksShape
  mockLinksButtionShouldShow: boolean
  mockLinksTextAlignment: LinksTextAlignment
  changeState: 'dirty' | 'clean'
  isSaving: boolean
  isSavingSectionIndex: number
}

export type ViewContext = 'Editor' | 'Preview' | 'Styles-Modal'

export enum BackgroundMode {
  COLOR = 'color',
  IMAGE = 'image',
}

export enum BackgroundImageOverlay {
  DARK_OVERLAY = 'dark_overlay',
  NONE = 'none',
  LIGHT_OVERLAY = 'light_overlay',
}

export enum BackgroundImageEffect {
  BLUR = 'blur',
  NONE = 'none',
}

export enum LinksShape {
  ROUNDED = 'rounded',
  CURVED = 'curved',
  STRAIGHT = 'straight',
}

export enum LinksTextAlignment {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum LinkView {
  BANNER = 'banner',
  PILL = 'pill',
  YOUTUBE_VIDEO = 'youtubeVideo',
}

export const getDefaultLinkSettings = (backgroundColor: string) => ({
  linksShape: LinksShape.ROUNDED,
  linksBackgroundColor: convertRGBAToHex(
    'rgba(255, 255, 255, 0.2)',
  ).toUpperCase(),
  linksTextColor: getTextColor(backgroundColor).toUpperCase(),
  linksButtionShouldShow: true,
  linksTextAlignment: LinksTextAlignment.LEFT,
})

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type PeigeSettings = {
  backgroundMode: BackgroundMode
  backgroundColor: string
  isGradient: boolean
  backgroundImage: string
  backgroundImagePosition: BackgroundImagePosition
  backgroundImageOverlay: BackgroundImageOverlay
  backgroundImageEffect: BackgroundImageEffect
  font: string
  linksTextColor: string
  linksBackgroundColor: string
  linksShape: LinksShape
  linksButtionShouldShow: boolean
  linksTextAlignment: LinksTextAlignment
  // linkView: LinkView
}

export enum BackgroundImagePosition {
  TOP = 'top',
  CENTER = 'center',
  BOTTOM = 'bottom',
  FULL = 'full',
}
