import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RestLink } from 'apollo-link-rest';
import Cookies from 'js-cookie';

const restLink = new RestLink({ uri: process.env.REACT_APP_API_BASE_URL });

const authLink = setContext((_, { headers }) => {
  const token = Cookies.get("token") ?? sessionStorage.getItem("token")

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});


export const client = new ApolloClient({
  link: authLink.concat(restLink),
  cache: new InMemoryCache(),
  credentials: "same-origin",
});
