import { gql } from '@apollo/client';

export const GROUP_SIGNUP_MUTATION = gql`
  fragment GroupSignupInput on REST {
    name: String
    username: String
    email: String
    password: String
  }

  mutation GroupSignup($input: GroupSignupInput!) {
    groupSignup(input: $input) @rest(type: "GroupSignup", method: "POST", path: "/auth/signup-group") {
      message
      token
    }
  }
`;