import { type IIcons } from './icon.types'

export const GroupsIcon = ({ mode, activeStatus }: IIcons) => {
  const color = {
    light: {
      active: '#040404',
      inactive: '#808080',
    },
    dark: {
      active: '#F5CE6F',
      inactive: '#F5F5F5',
    },
  }[mode][activeStatus]

  return (
    <svg
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        // eslint-disable-next-line max-len
        d="M17.8 20.1875H3.80005C2.1432 20.1875 0.800049 18.8444 0.800049 17.1875V3.1875C0.800049 1.53065 2.1432 0.1875 3.80005 0.1875H17.8C18.3523 0.1875 18.8 0.63522 18.8 1.1875V19.1875C18.8 19.7398 18.3523 20.1875 17.8 20.1875ZM16.8 18.1875V16.1875H3.80005C3.24777 16.1875 2.80005 16.6352 2.80005 17.1875C2.80005 17.7398 3.24777 18.1875 3.80005 18.1875H16.8ZM9.80005 8.1875C10.9046 8.1875 11.8 7.29207 11.8 6.1875C11.8 5.08293 10.9046 4.1875 9.80005 4.1875C8.69545 4.1875 7.80005 5.08293 7.80005 6.1875C7.80005 7.29207 8.69545 8.1875 9.80005 8.1875ZM6.80005 12.1875H12.8C12.8 10.5306 11.4569 9.1875 9.80005 9.1875C8.14315 9.1875 6.80005 10.5306 6.80005 12.1875Z"
        fill={color}
      />
    </svg>
  )
}
