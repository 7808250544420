import { Box, Icon, Input, Text, useColorMode } from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import { type FC, useState } from 'react'
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai'
import './input.css'
import theme from 'theme'
import { type IInput } from 'typings/interface'

const PasswordInput: FC<IInput> = ({
  placeholder,
  background,
  border,
  required,
  name,
  disabled,
  value,
  values,
  onChange,
  errorMessage,
  showMessage,
  color,
  borderRadius,
  width,
}: IInput) => {
  const [showPassword, setShowPassword] = useState(false)
  const { colorMode } = useColorMode()
  const [isActive, setIsActive] = useState(false)

  function handleTextChange(text: string): void {
    if (text !== '') {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }

  const error = theme.colors.default.error
  const bg = theme.colors.text.primary.default
  const themeColor = theme.colors.background.primary._dark
  const borderColor =
    colorMode === 'dark'
      ? theme.colors.border.default
      : theme.colors.border._dark

  return (
    <>
      <Box 
        position="relative" 
        {...(width ? {width} : {})}
      >
        <Box
          position={'relative'}
          display="flex"
          flexDirection={'column'}
          id="custom-input-password-wrapper"
          outline={'none'}
          borderRadius={borderRadius ?? '8px'}
          background={background ?? bg}
          border={
            border ??
            (errorMessage ? '1px solid #EB001B !important' : '1px solid')
          }
          borderColor={borderColor}
          overflow={'hidden'}
        >
          <Input
            id="input-password-field"
            padding={'1.5rem 1rem'}
            // placeholder={placeholder}
            background={'transparent'}
            required={required ?? false}
            type={showPassword ? 'text' : 'password'}
            name={name}
            disabled={disabled ?? false}
            value={values ? values[name] : value}
            onChange={(e: any) => {
              onChange?.(e)
              handleTextChange(e.target.value)
            }}
            // border={errorMessage ? "1px solid #E53E3E !important" : `none`}
            border={0}
            _hover={{
              border: 0,
            }}
            _focus={{
              boxShadow: 'none',
              border: errorMessage ?? 0,
              borderRadius: borderRadius ?? '7px',
            }}
            _disabled={{ opacity: 0.9 }}
            fontSize={'15px'}
            color={color ?? themeColor}
            fontWeight="400"
            letterSpacing={'3px'}
          />{' '}
          <label
            id="input-password-label"
            htmlFor={'password'}
            className={isActive ? 'Active' : ''}
            style={{
              transform: `${
                value ?? values?.[name] ? 'translate(15px, 2px) scale(0.6)' : ''
              }`,
              zIndex: `${value ?? values?.[name] ? '2' : ''}`,
            }}
          >
            {placeholder}
          </label>
        </Box>
        <Box
          position="absolute"
          right="0.5rem"
          top="1rem"
          padding="0 5px"
          margin="0"
          zIndex={3}
        >
          <Text
            margin="0"
            fontWeight="400"
            fontSize="12px"
            color={themeColor}
            cursor="pointer"
            onClick={() => {
              setShowPassword(!showPassword)
            }}
          >
            {showPassword ? (
              <Icon as={AiOutlineEyeInvisible} color={themeColor} w={5} h={5} />
            ) : (
              <Icon as={AiOutlineEye} color={themeColor} w={5} h={5} />
            )}
          </Text>
        </Box>
        <Box textAlign={'left'} padding="0.3rem 0" zIndex={3}>
          <Text color={error} fontSize={'12px'} fontWeight="500">
            {showMessage ? errorMessage : ''}
          </Text>
        </Box>
      </Box>
    </>
  )
}

export const FormikFormPasswordInput: FC<
  Omit<IInput, 'handleChange'> & { name: string; showMessage: boolean }
> = ({ name, showMessage, ...rest }) => {
  const { handleChange, values, errors, touched } = useFormikContext()
  const hasError =
    (errors as any)[name] && (touched as any)[name] && (errors as any)[name]

  return (
    <PasswordInput
      {...rest}
      name={name}
      onChange={handleChange}
      value={(values as any)[name as any]}
      showMessage={showMessage}
      errorMessage={hasError}
    />
  )
}

export default PasswordInput
