import {
  type TableData
} from "pages/Group/components/types";
import {
  BackgroundImagePosition, BackgroundImageEffect, BackgroundImageOverlay} from "utils/store/editor.collection";

export interface WithRedirectInterface {
  from: any;
  to: any;
}

export enum IndividualLocationApprovalStatus {
  APPROVED = "approved",
  REJECTED = "rejected",
}


export interface GetIndividualV2 {
  _id: string,
  email: string,
  avatar: string,
  status: AccountStatus,
  isVerified: boolean,
  isNewUser: boolean,
  isPeigePublished: boolean,
  isPeigeShared: boolean,
  hasSentFeedback: boolean,
  thingsToDo: {
    isComplete: boolean,
    toDos: {
      claimUsername: boolean,
      verifyEmail: boolean,
      completeOnboarding: boolean,
      addSection: boolean,
      fillYourNames: boolean,
      uploadApicture: boolean,
    },
  },
  profile: {
    firstname: string,
    lastname: string,
    username: string,
    usernameEditable: boolean,
  },
  preferences: {
    isPublished: boolean,
    peigePublishMode: PeigePublishMode,
    peigeID: string,
  },
  meta: {
    favicon: string,
    title: string,
    description: string,
  },
  onboardingProfile: Profile;
  locationInfo: {
    address: string;
    requestedLocationAt: Date;
    hasRequestedLocation: boolean;
    approvalStatus: IndividualLocationApprovalStatus;
    coordinates: {
      lng: number;
      lat: number;
    };
  };
  recentlyUsedTemplate: Date;
  autoPublishButtonText: AutoPublishButtonText;
  role: IndividualRole;
  groupID: string;
  pendingGroupInviteList: Array<{
    groupID: string;
    name: string;
    description: string;
  }>;
  currentGroupBranding: {
    useGroupBranding: boolean;
    useGroupName: boolean;
    groupName: string;
    groupLogo: string;
    groupID: string;
  };
  platformAccountID: string;
  apiKey: string;
}

export enum IndividualRole {
  GROUP_ADMIN = "group_admin",
  NORMAL_USER = "normal_user",
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type TemplateDraft = {
  templateID: string,
  templateName: string,
  isInApp: boolean,
  description: string,
  coverImage: string,
};

export interface Profile {
  birthday: {day: string, month: string, year: string};
  gender: string;
  interests: string[];
  isBusiness: boolean;
  profession: string;
}


export enum AccountStatus {
  BANNED = "banned",
  SUSPENDED = "suspended",
  ACTIVE = "active",
  INACTIVE = "inactive",
  DEACTIVATED = "deactivated",
}

export enum PeigePublishMode {
  AUTO_PUBLISH = "autopublish",
  MANUAL = "manual"
}

export enum AutoPublishButtonText {
  CONTINUE_AUTO_PUBLISH = "Continue Auto-Publish",
  SHARE = "Share",
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type Group = {
  creatorID: string;
  name: string;
  description: string;
  url: string;
  logo: string;
  backgroundImage: string;
  backgroundImagePosition: BackgroundImagePosition;
  backgroundImageOverlay: BackgroundImageOverlay;
  backgroundImageEffect: BackgroundImageEffect;
  useGroupName: boolean;
};

export enum GroupMemberActiveStatus {
  ACTIVATED = "activated",
  DEACTIVATED = "deactivated",
}


// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type GroupMembers = {
  group: Group,
  activeGroupMembers: number;
  invitedGroupMembers: number;
  groupMembers: TableData[],
};

export const DefaultGroupMembers = {
  group: {
    creatorID: "",
    name: "",
    description: "",
    url: "",
    logo: "",
    backgroundImage: "",
    backgroundImagePosition: BackgroundImagePosition.FULL,
    backgroundImageOverlay: BackgroundImageOverlay.LIGHT_OVERLAY,
    backgroundImageEffect: BackgroundImageEffect.NONE,
    useGroupName: false,
  },
  activeGroupMembers: 0,
  invitedGroupMembers: 0,
  groupMembers: [],
};
