import { type FC, Suspense, lazy } from 'react'
import { Route, Routes as RouteWrapper, Navigate, useLocation } from 'react-router-dom'
import { ROUTES } from './links'
import { AuthLayout, DashboardLayout } from '../Layout'
import { ColorModeSwitcher } from '../ColorModeSwitcher'
import { Box } from '@chakra-ui/react'
import PageLoader from 'compositions/PageLoader'
import AuthRouteProvider from './AuthRouteProvider'
import OnboardingBG from 'Layout/Onboarding'
import ErrorPage from 'components/ErrorPage'
import GroupInvite from 'pages/Dashboard/GroupInvite'
import FormAnalytics from 'pages/Dashboard/components/FormAnalytics'

const Login = lazy(async () => await import('pages/Auth/Login'))
const Register = lazy(async () => await import('pages/Auth/Register'))
const PasswordReset = lazy(async () => await import('pages/Auth/PasswordReset'))
const Onboarding = lazy(async () => await import('pages/Onboarding/index'))
const Group = lazy(async () => await import('pages/Group/index'))
const Overview = lazy(async () => await import('pages/Dashboard/Overview'))
const DemoDashboard = lazy(async () => await import('pages/Dashboard2/index'))

const Editor = lazy(async () => await import('pages/Editor'))
const Analytics = lazy(async () => await import('pages/Dashboard/Analytics'))
const Settings = lazy(async () => await import('pages/Dashboard/Settings'))
const OldTemplates = lazy(
  async () => await import('pages/Dashboard/OldTemplates'),
)
// const Templates = lazy(async () => await import('pages/Dashboard/Templates'))

const RedirectWithQuery = ({ to }: { to: string }) => {
  const location = useLocation();
  const searchParams = location.search;
  return <Navigate to={`${to}${searchParams}`} replace />;
};

const Routes: FC = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Box pos={'absolute'} top={'1rem'} right={'0.625rem'} zIndex={11}>
        <ColorModeSwitcher justifySelf="flex-end" />
      </Box>
      <RouteWrapper>
        <Route path={ROUTES.auth} element={<AuthLayout />}>
          <Route index element={<Login />} />
          <Route path={ROUTES.signup} element={<Register type={'individual'} />} />
          <Route path={ROUTES.platformRegister} element={<Register type={'platform'} />} />
          <Route path={ROUTES.groupRegister} element={<Register type={'group'} />} />
          <Route path={ROUTES.passwordReset} element={<PasswordReset />} />
          <Route
            path={ROUTES.login}
            element={<RedirectWithQuery to={ROUTES.auth} />}
          />
        </Route>
        {/* Protected Routes */}

        {/* Protected Routes */}
        <Route
          path={ROUTES.onboarding}
          element={
            <AuthRouteProvider>
              <OnboardingBG />
            </AuthRouteProvider>
          }
        >
          <Route path={ROUTES.onboarding} element={<Onboarding />} />
        </Route>

        <Route
          path={ROUTES.dashboard}
          element={
            <AuthRouteProvider>
              <DashboardLayout />
            </AuthRouteProvider>
          }
        >
          <Route index element={<Overview />} />

          <Route path={ROUTES.demodashboard} element={<DemoDashboard />} />
          <Route path={ROUTES.groupInvite} element={<GroupInvite />} />
          <Route path={ROUTES.pageViews} element={<Analytics />} />
          <Route path={ROUTES.linkClicks} element={<Analytics />} />
          <Route path={ROUTES.formSubmissions} element={<FormAnalytics />} />
          <Route path={ROUTES.editor} element={<Editor />} />
          <Route path={ROUTES.settings} element={<Settings />} />
          <Route path={ROUTES.templates} element={<OldTemplates />} />
          <Route path={ROUTES.group} element={<Group />} />
          <Route path={ROUTES.overview} element={<Navigate to="/" replace />} />
          <Route
            path={ROUTES.analytics}
            element={<Navigate to="/" replace />}
          />
        </Route>

        <Route
          path={ROUTES.notLogin}
          element={<Navigate to="/auth/login" replace />}
        />
        <Route
          path={ROUTES.notSignup}
          element={<Navigate to="/auth/register" replace />}
        />
        <Route path={ROUTES.notFound} element={<ErrorPage />} />
      </RouteWrapper>
    </Suspense>
  )
}

export default Routes
