import { type SectionKindType } from "pages/Editor/types";

const editorIconList: Array<{ name: string; value: SectionKindType }> = [
  { name: 'Text', value: 'text' },
  { name: 'Social Icons', value: 'social_media' },
  { name: 'Links', value: 'link' },
  { name: 'Title', value: 'title' },
  { name: 'Spacer', value: 'spacer' },
  { name: 'Image/Text', value: 'image_text' },
  // { name: 'Group', value: 'group_section' },
]

const List = {
  editorIconList,
}

export default List
