import Cookies from 'js-cookie'
import { signal } from '@preact/signals-react'
import { useLocation, useNavigate } from 'react-router-dom'
import { createContext, useEffect } from 'react'

import { ROUTES } from './links'
import { tokenExpiredSync } from 'helper'
import { minutesFromNow } from 'utils/time'
import { useCurrentUser } from 'hooks/useCurrentUser'
import { 
  type GetIndividualV2, IndividualLocationApprovalStatus, 
  type Profile, AccountStatus, PeigePublishMode, AutoPublishButtonText, IndividualRole, 
} from './types'


const defaultProfile: Profile = {
  birthday: {day: "00", month: "00", year: "0000"},
  gender: "male",
  interests: [],
  isBusiness: false,
  profession: "software enginner"
};

const defaultCurrentUser: GetIndividualV2 = {
  _id: "",
  email: "",
  avatar: "",
  status: AccountStatus.ACTIVE,
  isVerified: true,
  isNewUser: false,
  isPeigePublished: true,
  isPeigeShared: true,
  hasSentFeedback: true,
  thingsToDo: {
    isComplete: true,
    toDos: {
      claimUsername: true,
      verifyEmail: true,
      completeOnboarding: true,
      addSection: true,
      fillYourNames: true,
      uploadApicture: true,
    },
  },
  profile: {
    firstname: "",
    lastname: "",
    username: "",
    usernameEditable: true,
  },
  preferences: {
    isPublished: true,
    peigePublishMode: PeigePublishMode.MANUAL,
    peigeID: "",
  },
  meta: {
    favicon: "",
    title: "",
    description: "",
  },
  onboardingProfile: defaultProfile,
  locationInfo: {
    address: "",
    requestedLocationAt: null as any, // i need the value type in overview.tsx
    hasRequestedLocation: false,
    approvalStatus: IndividualLocationApprovalStatus.REJECTED,
    coordinates: {
      lng: 0,
      lat: 0,
    },
  },
  recentlyUsedTemplate: minutesFromNow(-10),
  autoPublishButtonText: AutoPublishButtonText.SHARE,
  role: IndividualRole.NORMAL_USER,
  groupID: "",
  pendingGroupInviteList: [],
  currentGroupBranding: {
    useGroupBranding: false,
    useGroupName: false,
    groupName: "",
    groupLogo: "",
    groupID: "",
  },
  platformAccountID: "",
  apiKey: "",
};

export const CurrentUserContext = createContext<
  { currentUser: GetIndividualV2, profile: Profile, setCurrentUser: (currentUser: GetIndividualV2) => void }
>({} as any)

export interface CurrentUserData { 
  currentUser: GetIndividualV2, 
  profile: Profile 
}

const isGroupInviteLink = (pathname: string) => {
  return pathname.startsWith("/group-invite/");
};

export const CurrentUserStore = signal<CurrentUserData & {loading: boolean}>({
  loading: true,
  currentUser: defaultCurrentUser,
  profile: defaultProfile,
});

interface AuthRouteProviderProps {
  children: React.ReactNode
}

const AuthRouteProvider = ({ children }: AuthRouteProviderProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  // TODO: useEffect hook to check for the cookie on mount
  useEffect(() => {
    const pathname = location.pathname;
    if (isGroupInviteLink(pathname)) {
      const groupID = pathname.substring(14, 38);
      if (groupID) {
        localStorage.setItem('groupInviteID', groupID);
      }
    }

    const loggedInUserToken =
      Cookies.get('token') ?? sessionStorage.getItem('token')
    if (!loggedInUserToken) navigate(ROUTES.login)
  }, [location, navigate])

  const {loading, data, error } = useCurrentUser();

  if (loading) {
    // TODO: show loading skeleton
    return null
  }

  if (error) {
    // TODO: navigate to login
    // too strict -- hard to debug errors
    tokenExpiredSync()
    navigate(ROUTES.login)
  }

  if (!data && !loading) {
    // fetchCurrentUser();
    return null;
  }

  const { currentUser, profile } = CurrentUserStore.value;
  const setCurrentUser = (_currentUser: GetIndividualV2) => {
    const { onboardingProfile } = _currentUser;
    const CurrentUserData = {
      loading: false,
      currentUser: _currentUser,
      profile: onboardingProfile,
    };
    CurrentUserStore.value = CurrentUserData;
  };

  return (
    <CurrentUserContext.Provider value={{ currentUser, profile, setCurrentUser }}>
      {children}
    </CurrentUserContext.Provider>
  )
}

export default AuthRouteProvider
