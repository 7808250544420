import { useMutation } from '@apollo/client'
import { signal } from '@preact/signals-react'

import {
  Box,
  Flex,
  Image,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { type TopbarInterface } from './types'
import { type FC, useState, useContext } from 'react'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'routes/links'
import { Button, Loader } from 'components'
import theme from 'theme'
import ShareModal from 'components/AppModals/ShareModal'
import { CurrentUserContext } from 'routes/AuthRouteProvider'
import { PUBLISH_PEIGE_LIVE_MUTATION } from 'apolloClient/mutations/publishPeige'
import DemoModal from 'components/Modal/DemoModal'
import { AutoPublishButtonText, PeigePublishMode } from 'routes/types'

export const ChangeState = signal<'dirty' | 'clean'>('clean')
export const ShareModalState = signal({
  openShareDrawer: false,
})

const Topbar: FC<TopbarInterface> = ({
  colorMode,
  editorActive,
  isEditor,
  isWeb,
  username,
  view,
  setView,
}: TopbarInterface) => {
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext)
  const { profile, preferences } = currentUser

  const [publishPeigeLiveMutation] = useMutation(PUBLISH_PEIGE_LIVE_MUTATION)

  const navigate = useNavigate()
  const currentDate = new Date()
  const today = `${currentDate.toLocaleString('default', {
    month: 'long',
  })}, ${currentDate.getDate()}`
  const [saving] = useState(false)
  const [publishing, setPublishing] = useState(false)
  const [showDirtyMessage] = useState<boolean>(false)
  const peigePublishMode = preferences.peigePublishMode
  const canPublish = preferences.isPublished && ChangeState.value === 'dirty'

  const handlePublishPeige = () => {
    setPublishing(true)
    publishPeigeLiveMutation({
      variables: {
        input: {},
      },
      update: (_, result) => {
        ChangeState.value = 'clean'
        if (peigePublishMode === PeigePublishMode.MANUAL) {
          ShareModalState.value = { openShareDrawer: true }
        }
        setCurrentUser({
          ...currentUser,
          autoPublishButtonText: AutoPublishButtonText.SHARE,
        })
        setPublishing(false)
      },
    })
  }

  const toggleModal = () => {
    ShareModalState.value = { openShareDrawer: !ShareModalState.value.openShareDrawer};
  }
  // const [openShare, setOpenShare] = useState<boolean>(false)
  const darkBg = theme.colors.background.primary._dark
  const lightBg = theme.colors.background.primary.default

  const colorModeBg = colorMode === 'light' ? lightBg : darkBg
  const colorModeIcon = useColorModeValue('black', 'white')

  return (
    <Flex
      top={0}
      w={'100%'}
      h={'70px'}
      zIndex={10}
      pos={{ base: 'fixed', md: 'sticky' }}
      justify={'center'}
      align={'center'}
      bg={colorModeBg}
      boxShadow={`0px 1px 0px ${
        colorMode === 'light'
          ? 'rgba(0, 0, 0, 0.05)'
          : 'rgba(255, 255, 255, 0.05)'
      }`}
    >
      <Flex
        w={'full'}
        maxW={'1160px'}
        h={'69px'}
        justify={'center'}
        align={'center'}
        px={'16px'}
      >
        {isEditor ? (
          <>
            <DemoModal
              isOpen={ShareModalState.value.openShareDrawer}
              onClose={toggleModal}
              padding="1.3rem 1.12rem"
              maxW="30rem"
            >
              <ShareModal username={profile.username} />
            </DemoModal>

            <Flex
              w={'full'}
              paddingRight={{ base: '35px', md: '30px' }}
              paddingLeft={{ base: '0px', md: '10px' }}
              justify={{ base: 'space-between' }}
              align={'center'}
            >
              {isWeb ? (
                <>
                  <Text
                    fontSize={'14px'}
                    cursor={'pointer'}
                    onClick={() => {
                      ShareModalState.value = { openShareDrawer: true }
                    }}
                    // onClick={onOpen}
                    mr={'16px'}
                  >
                    {process.env.REACT_APP_PUBLIC_URL?.split('//')[1]}/
                    {username}
                  </Text>
                </>
              ) : (
                <Flex align={'center'} justify={'center'}>
                  <Box
                    onClick={() => {
                      navigate(ROUTES.dashboard)
                    }}
                    borderRadius="50%"
                    bgColor="rgba(194, 193, 193, 0.3)"
                    width="28px"
                    height="28px"
                    display="flex"
                    justifyContent={'center'}
                    alignItems="center"
                    cursor={'pointer'}
                  >
                    <ChevronLeftIcon
                      color={colorMode === 'light' ? 'black' : 'white'}
                      w={6}
                      h={6}
                    />
                  </Box>
                  <Flex
                    align={'center'}
                    cursor={'pointer'}
                    onClick={() => {
                      ShareModalState.value = { openShareDrawer: true }
                    }}
                    // onClick={onOpen}
                  >
                    <Text fontSize={'12px'} fontWeight={500} mr={'4px'} ml={2}>
                      {profile.username.length > 8
                        ? `${profile.username.substring(6)}...`
                        : profile.username}
                    </Text>
                    <Image
                      src={
                        colorModeIcon === 'white'
                          ? `svgs/mobile.share.dark.svg`
                          : `svgs/mobile.share.white.svg`
                      }
                    />
                  </Flex>
                </Flex>
              )}
              <Flex
                py="15px"
                w={'fit-content'}
                gap={{ base: '5px', md: '7px' }}
                alignItems="center"
              >
                <Button
                  text={view === 'Editor' ? 'Preview' : 'Editor'}
                  h={'fit-content'}
                  color={colorMode === 'dark' ? 'white' : 'black'}
                  bg={'transparent'}
                  fontSize={{ base: '11px', md: '14px' }}
                  fontWeight="500"
                  borderRadius={'8px'}
                  padding={'5px 8px'}
                  border={'1px solid'}
                  _hover={{
                    color: colorMode === 'dark' ? 'white' : 'black',
                    backgroundColor: 'transparent',
                    border: '1px solid',
                  }}
                  _active={{
                    color: colorMode === 'dark' ? 'white' : 'black',
                    backgroundColor: 'transparent',
                    border: '1px solid',
                  }}
                  borderColor={colorMode === 'dark' ? 'white' : 'black'}
                  isDisabled={editorActive}
                  onClick={() => {
                    setView(view === 'Editor' ? 'Preview' : 'Editor')
                  }}
                />

                <Flex align={'center'}>
                  <Box
                    display={saving ? 'flex' : 'none'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    h={
                      peigePublishMode === PeigePublishMode.AUTO_PUBLISH
                        ? '20px'
                        : '50px'
                    }
                  >
                    <Loader pageName="saving" />
                  </Box>
                  <Box display={!isWeb && saving ? 'none' : 'flex'}>
                    {view === 'Editor' &&
                      peigePublishMode === PeigePublishMode.MANUAL && (
                        <Flex
                          flexDir={'column'}
                          placeItems={'center'}
                          pos={'relative'}
                        >
                          <Button
                            text={'Publish'}
                            fontWeight="500"
                            h={'fit-content'}
                            fontSize={{ base: '11px', md: '14px' }}
                            isLoading={publishing}
                            isDisabled={!canPublish}
                            color={colorMode === 'light' ? 'white' : 'black'}
                            bg={colorMode === 'light' ? 'black' : 'white'}
                            _hover={{
                              bg: colorMode === 'light' ? 'black' : 'white',
                            }}
                            _focus={{
                              bg: colorMode === 'light' ? 'black' : 'white',
                            }}
                            borderRadius={'8px'}
                            padding="6px 10px"
                            borderLeft={'1px solid'}
                            borderColor={
                              colorMode === 'light' ? 'white' : 'black'
                            }
                            onClick={handlePublishPeige}
                          />
                          {ChangeState.value === 'dirty' && (
                            <Box
                              pos={'absolute'}
                              top={showDirtyMessage ? '-10px' : '-3px'}
                              right={'-3px'}
                              w={showDirtyMessage ? 'max-content' : '12px'}
                              h={showDirtyMessage ? 'auto' : '12px'}
                              p={showDirtyMessage ? '2px 10px' : 0}
                              borderRadius={showDirtyMessage ? '10px' : '50%'}
                              bg={'#D1A22E'}
                              onMouseEnter={() => {}}
                              onMouseLeave={() => {}}
                              onClick={handlePublishPeige}
                            >
                              {showDirtyMessage && (
                                <Text fontSize={'xs'}>
                                  You have some unpublished changes
                                </Text>
                              )}
                            </Box>
                          )}
                        </Flex>
                      )}
                    {view === 'Editor' &&
                      peigePublishMode === PeigePublishMode.AUTO_PUBLISH && (
                        <Flex
                          flexDir={'column'}
                          placeItems={'center'}
                          pos={'relative'}
                        >
                          <Button
                            text={
                              currentUser.autoPublishButtonText
                            }
                            fontWeight="500"
                            h={'fit-content'}
                            fontSize={{ base: '11px', md: '14px' }}
                            isLoading={publishing}
                            color={colorMode === 'light' ? 'white' : 'black'}
                            bg={colorMode === 'light' ? 'black' : 'white'}
                            _hover={{
                              bg: colorMode === 'light' ? 'black' : 'white',
                            }}
                            _focus={{
                              bg: colorMode === 'light' ? 'black' : 'white',
                            }}
                            borderRadius={'8px'}
                            padding="6px 10px"
                            borderLeft={'1px solid'}
                            borderColor={
                              colorMode === 'light' ? 'white' : 'black'
                            }
                            onClick={() => {
                              if (currentUser.autoPublishButtonText === AutoPublishButtonText.CONTINUE_AUTO_PUBLISH) {
                                handlePublishPeige();
                              }else {
                                ShareModalState.value = { openShareDrawer: true }
                              }
                            }}
                          />
                        </Flex>
                      )}
                  </Box>
                </Flex>
              </Flex>
            </Flex>
          </>
        ) : (
          <Flex flexDir={'row'}>
            <Image
              src={'/images/dateCal.svg'}
              w={'20px'}
              h={'20px'}
              alt={'Today'}
              loading={'lazy'}
            />
            <Text ml={'5px'} opacity={0.5} fontSize={'14px'}>
              Today
            </Text>
            <Text ml={'5px'} fontSize={'14px'} opacity={0.8}>
              {today}
            </Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default Topbar
