/* eslint-disable no-useless-escape */
import * as yup from "yup";

// const socialIcons = [
//   "instagram",
//   "twitter",
//   "facebook",
//   "tiktok",
//   "snapchat",
//   "linkedin",
//   "github",
//   "dribbble",
//   "behance",
//   "whatsapp",
//   "email",
// ];

const socialIconsSchema = yup.object().shape({
  instagram: yup
    .string()
    .ensure()
    .trim("Social media handle cannot contain spaces")
    .matches(/^(?!.*(?:http|https|www)).*$/, 'Input your username or handle not links')
    .strict(true),
  whatsapp: yup
    .string()
    .ensure()
    .trim("Social media handle cannot contain spaces")
    .matches(/^(?!.*(?:http|https|www)).*$/, 'Input your username or handle not links')
    .strict(true),
  email: yup
    .string()
    .ensure()
    .trim("Social media handle cannot contain spaces")
    .matches(/^(?!.*(?:http|https|www)).*$/, 'Input your username or handle not links')
    .strict(true),
  twitter: yup
    .string()
    .ensure()
    .trim("Social media handle cannot contain spaces")
    .matches(/^(?!.*(?:http|https|www)).*$/, 'Input your username or handle not links')
    .strict(true),
  facebook: yup
    .string()
    .ensure()
    .trim("Social media handle cannot contain spaces")
    .matches(/^(?!.*(?:http|https|www)).*$/, 'Input your username or handle not links')
    .strict(true),
  tiktok: yup
    .string()
    .ensure()
    .trim("Social media handle cannot contain spaces")
    .matches(/^(?!.*(?:http|https|www)).*$/, 'Input your username or handle not links')
    .strict(true),
  snapchat: yup
    .string()
    .ensure()
    .trim("Social media handle cannot contain spaces")
    .matches(/^(?!.*(?:http|https|www)).*$/, 'Input your username or handle not links')
    .strict(true),
  linkedin: yup
    .string()
    .ensure()
    .trim("Social media handle cannot contain spaces")
    .matches(/^(?!.*(?:http|https|www)).*$/, 'Input your username or handle not links')
    .strict(true),
  github: yup
    .string()
    .ensure()
    .trim("Social media handle cannot contain spaces")
    .matches(/^(?!.*(?:http|https|www)).*$/, 'Input your username or handle not links')
    .strict(true),
  dribbble: yup
    .string()
    .ensure()
    .trim("Social media handle cannot contain spaces")
    .matches(/^(?!.*(?:http|https|www)).*$/, 'Input your username or handle not links')
    .strict(true),
  behance: yup
    .string()
    .ensure()
    .trim("Social media handle cannot contain spaces")
    .matches(/^(?!.*(?:http|https|www)).*$/, 'Input your username or handle not links')
    .strict(true),
  // selection: yup.string().when(socialIcons, {
  //   is: (
  //     instagram: string,
  //     twitter: string,
  //     facebook: string,
  //     tiktok: string,
  //     snapchat: string,
  //     linkedin: string,
  //     github: string,
  //     dribbble: string,
  //     whatsapp: string,
  //     email: string,
  //     behance: string
  //   ) =>
  //     !instagram &&
  //     !twitter &&
  //     !facebook &&
  //     !tiktok &&
  //     !snapchat &&
  //     !linkedin &&
  //     !github &&
  //     !whatsapp &&
  //     !email &&
  //     !dribbble &&
  //     !behance,
  //   then: yup.string().required("At least one social media must be provided"),
  // }),
});

export default socialIconsSchema;
