import { signal } from '@preact/signals-react'

import { client } from 'apolloClient'
import type { SectionData } from '../../pages/Editor/types'
import { updateEditorInCache } from 'utils/updateCache'

import { EDITOR_QUERY } from 'apolloClient/queries'
import { UPDATE_EDITOR_MUTATION } from 'apolloClient/mutations/editor'
import { UPDATE_STYLE_SETTINGS } from 'apolloClient/mutations/settings'
import { DELETE_ALL_SECTIONS_MUTATION } from 'apolloClient/mutations/deleteAllSections'
import {
  type PeigeSettings,
  type EditorCollection,
  BackgroundImageOverlay,
  BackgroundImagePosition,
  BackgroundImageEffect,
  BackgroundMode,
  LinksShape,
  LinksTextAlignment,
} from 'utils/store/editor.collection'
import { UNDO_DELETE_ALL_SECTIONS_MUTATION } from 'apolloClient/mutations/undoDeleteAllSections'
import {
  hideUndoDeleteAllSectionsDialog,
  hideUndoDeleteSingleSectionDialog,
  showUndoDeleteAllSectionsDialog,
  showUndoDeleteSingleSectionDialog,
} from './editor.effects'
import { ChangeState } from 'Layout/Dashboard/parts/Topbar'
import { DELETE_SINGLE_SECTION_MUTATION } from 'apolloClient/mutations/deleteSingleSection'
import { UNDO_DELETE_SINGLE_SECTIONS_MUTATION } from 'apolloClient/mutations/undoDeleteSingleSection'
import convertRGBAToHex from 'utils/convertRGBAToHex'
import { getTextColor } from 'utils/color.checker'

interface BaseSections {
  peigeID: string
  sections: SectionData[]
  settings: PeigeSettings
  changeState: 'dirty' | 'clean'
}

export const storeProxyHandler: ProxyHandler<(...args: any[]) => void> = {
  apply(target, _thisArg, argList) {
    ;(async () => {
      await (target as unknown as (...args: any[]) => Promise<void>)(...argList)
      store.value = {
        ...store.value,
        ...storeProxy,
      }
    })()
  },
}

export const updateEditor = new Proxy(
  async (input: { peigeID: string; sections: SectionData[] }, currentSectionIndex?: number) => {
    storeProxy.isSaving = true;
    
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (!isNaN(currentSectionIndex!)) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      storeProxy.isSavingSectionIndex = currentSectionIndex!;
    }

    try {
      await client.mutate({
        mutation: UPDATE_EDITOR_MUTATION,
        variables: { input },
        update: (_, result) => {
          updateEditorInCache(client, result)
          const res = result.data.updateEditor as BaseSections
          if (!result.errors)
            updateProxy({ 
              res, 
              kind: 'sections', 
              changeState: res.changeState,
              cb: () => {
                hideUndoDeleteAllSectionsDialog();
                hideUndoDeleteSingleSectionDialog();
              }
            })
        },
      })
    } finally {
      storeProxy.isSaving = false;
      // storeProxy.isSavingSectionIndex = -1;
    }
  },
  storeProxyHandler,
)

export const deleteSingleSection = new Proxy(
  async (input: { sectionID: string }, sectionIndex: number) => {
  await client.mutate({
    mutation: DELETE_SINGLE_SECTION_MUTATION,
    variables: { input },
    update: (_, result) => {
      updateEditorInCache(client, result)
      const res = result.data.deleteSingleSection as BaseSections
      if (!result.errors)
        updateProxy({
          res,
          kind: 'sections',
          changeState: res.changeState,
          cb: () => { 
            showUndoDeleteSingleSectionDialog(sectionIndex)
          },
        })
    },
  })
}, storeProxyHandler)

export const undoDeleteSingleSection = new Proxy(async () => {
  await client.mutate({
    mutation: UNDO_DELETE_SINGLE_SECTIONS_MUTATION,
    variables: { input: { url: 'some_url' } },
    update: (_, result) => {
      updateEditorInCache(client, result)
      const res = result.data.undoDeleteSingleSection as BaseSections
      if (!result.errors)
        updateProxy({
          res,
          kind: 'sections',
          changeState: res.changeState,
          cb: hideUndoDeleteSingleSectionDialog,
        })
    },
  })
}, storeProxyHandler)

export const deleteAllSections = new Proxy(async () => {
  await client.mutate({
    mutation: DELETE_ALL_SECTIONS_MUTATION,
    variables: { input: { url: 'some_url' } },
    update: (_, result) => {
      updateEditorInCache(client, result)
      const res = result.data.deleteAllSections as BaseSections
      if (!result.errors)
        updateProxy({
          res,
          kind: 'sections_and_settings',
          changeState: res.changeState,
          cb: showUndoDeleteAllSectionsDialog,
        })
    },
  })
}, storeProxyHandler)

export const undoDeleteAllSections = new Proxy(async () => {
  await client.mutate({
    mutation: UNDO_DELETE_ALL_SECTIONS_MUTATION,
    variables: { input: { url: 'some_url' } },
    update: (_, result) => {
      updateEditorInCache(client, result)
      const res = result.data.undoDeleteAllSections as BaseSections
      if (!result.errors)
        updateProxy({
          res,
          kind: 'sections_and_settings',
          changeState: res.changeState,
          cb: hideUndoDeleteAllSectionsDialog,
        })
    },
  })
}, storeProxyHandler)

export const updateStyleSettings = new Proxy(
  async (input: { settings: PeigeSettings }) => {
    try {
      await client.mutate({
        mutation: UPDATE_STYLE_SETTINGS,
        variables: { input },
        update: (_, result) => {
          if (!result.errors) {
            updateEditorInCache(client, result)
            const res = result.data.updateStyleSettings as BaseSections
            if (!result.errors)
              updateProxy({
                res,
                kind: 'sections_and_settings',
                changeState: res.changeState,
              })
          }
        },
      })
    } finally {
      storeProxy.isSaving = false;
    }
  },
  storeProxyHandler,
)

export const prefetchEditor = () => {
  client
    .query<{
      editor: BaseSections
    }>({ query: EDITOR_QUERY })
    .then(res => {
      const { loading, data } = res
      if (!loading && data) {
        initialiseStore(data)
      }
    })
}

export const getHeaderAndOtherSections = (allSections: SectionData[]) => ({
  headerSection: allSections[0],
  otherSections: allSections.slice(1),
})

interface UpdateProxy {
  res: BaseSections
  kind: 'sections' | 'sections_and_settings'
  changeState: 'dirty' | 'clean'
  cb?: (...args: any[]) => void
}

const updateProxy = ({ res, kind, changeState, cb }: UpdateProxy) => {
  const { headerSection, otherSections } = getHeaderAndOtherSections(
    res.sections,
  )
  const caller =
    kind === 'sections'
      ? updateSectionsInStoreProxy
      : updateSectionsAndSettingsInStoreProxy
  caller({
    peigeID: res.peigeID,
    sections: res.sections,
    headerSection,
    otherSections,
    settings: res.settings,
    changeState,
  })
  cb?.()
}

interface UpdateSectionsInStoreProxy {
  peigeID: string
  sections: SectionData[]
  headerSection: SectionData
  otherSections: SectionData[]
  settings: PeigeSettings
  changeState: 'dirty' | 'clean'
}

export const updateSectionsInStore = (
  {
    sections, 
  }: Pick<UpdateSectionsInStoreProxy, 'sections'>
) => {
  const [ headerSection, ...otherSections ] = sections;
  store.value = {
    ...store.value,
    data: {
      ...store.value.data,
      editor: {
        ...store.value.data.editor,
        sections,
      },
    },
    headerSection,
    otherSections,
    isSaving: false,
  }
};

const updateSectionsInStoreProxy = ({
  peigeID,
  sections,
  headerSection,
  otherSections,
  changeState,
}: UpdateSectionsInStoreProxy) => {
  storeProxy.data.editor.peigeID = peigeID
  storeProxy.data.editor.sections = sections
  storeProxy.headerSection = headerSection
  storeProxy.otherSections = otherSections
  storeProxy.changeState = changeState
  ChangeState.value = changeState
  storeProxy.isSaving = false
}

const updateSectionsAndSettingsInStoreProxy = ({
  peigeID,
  sections,
  settings,
  headerSection,
  otherSections,
  changeState,
}: UpdateSectionsInStoreProxy) => {
  storeProxy.data.editor.peigeID = peigeID
  storeProxy.data.editor.sections = sections
  storeProxy.data.editor.settings = settings
  storeProxy.headerSection = headerSection
  storeProxy.otherSections = otherSections
  storeProxy.isStylesModalOpen = false
  storeProxy.changeState = changeState
  ChangeState.value = changeState
  storeProxy.isSaving = false
}

export const store = signal<EditorCollection>({
  sectionKind: undefined,
  ctx: undefined,
  isEditorOpen: false,
  currentSectionIndex: 0,
  currentSectionData: null as any,
  data: null as any,
  headerSection: null as any,
  otherSections: [],
  isStylesModalOpen: false,
  viewContext: 'Editor',
  mockBGColor: '',
  mockFont: '',
  mockBGOverlay: BackgroundImageOverlay.NONE,
  mockBGPosition: BackgroundImagePosition.FULL,
  mockBGEffect: BackgroundImageEffect.NONE,
  mockBGMode: BackgroundMode.COLOR,
  mockLinksTextColor: '#FFFFFF',
  mockLinksBackgroundColor: convertRGBAToHex("rgba(255, 255, 255, 0.2)").toUpperCase(),
  mockLinksShape: LinksShape.ROUNDED,
  mockLinksButtionShouldShow: true,
  mockLinksTextAlignment: LinksTextAlignment.LEFT,
  changeState: 'clean',
  isSaving: false,
  isSavingSectionIndex: -1,
})
export const storeProxy: EditorCollection = { ...store.value }

export const initialiseStore = new Proxy((_data: { editor: BaseSections }) => {
  if (_data) {
    // @NOTE
    // safari sets the property descriptors to false
    // if we manually change the writable and configurable properties,
    // safari refuses with the error below,
    // ERROR: Attempting to change configurable attribute of unconfigurable property. defineProperty@[native code]
    const data = JSON.parse(JSON.stringify(_data)) as { editor: BaseSections }
    const { headerSection, otherSections } = getHeaderAndOtherSections(
      data.editor.sections,
    )
    storeProxy.data = data
    storeProxy.headerSection = headerSection
    storeProxy.otherSections = otherSections
    storeProxy.mockBGColor = data?.editor?.settings?.backgroundColor || ''
    storeProxy.mockFont = data?.editor?.settings?.font || ''
    storeProxy.mockBGOverlay = data?.editor?.settings?.backgroundImageOverlay || BackgroundImageOverlay.NONE
    storeProxy.mockBGPosition = data?.editor?.settings?.backgroundImagePosition || BackgroundImagePosition.FULL
    storeProxy.mockBGEffect = data?.editor?.settings?.backgroundImageEffect || BackgroundImageEffect.NONE
    storeProxy.mockBGMode = data?.editor?.settings?.backgroundMode || BackgroundMode.COLOR
    storeProxy.mockLinksTextColor = data?.editor?.settings?.linksTextColor || getTextColor(storeProxy.mockBGColor ||  "#FFFFFF");
    storeProxy.mockLinksBackgroundColor = data?.editor?.settings?.linksBackgroundColor || convertRGBAToHex("rgba(255, 255, 255, 0.2)").toUpperCase()
    storeProxy.mockLinksShape = data?.editor?.settings?.linksShape || LinksShape.ROUNDED
    // eslint-disable-next-line no-prototype-builtins
    storeProxy.mockLinksButtionShouldShow = data?.editor?.settings?.hasOwnProperty("linksButtionShouldShow") ? 
      data?.editor?.settings?.linksButtionShouldShow : true
    storeProxy.mockLinksTextAlignment = data?.editor?.settings?.linksTextAlignment || LinksTextAlignment.LEFT
    storeProxy.changeState = data?.editor?.changeState || 'clean'
    ChangeState.value = data?.editor?.changeState || 'clean'
  }
}, storeProxyHandler)

export const deleteAllSectionsStore = signal<{
  shouldShowYesOrNoDeleteAllSectionsDialog: boolean
  shouldShowUndoDeleteAllSectionsDialog: boolean
}>({
  shouldShowYesOrNoDeleteAllSectionsDialog: false,
  shouldShowUndoDeleteAllSectionsDialog: false,
})
export const deleteAllSectionsStoreProxy = { ...deleteAllSectionsStore.value }

export const deleteSingleSectionStore = signal<{
  shouldShowUndoDeleteSingleSectionDialog: boolean
  sectionIndex: number
}>({
  shouldShowUndoDeleteSingleSectionDialog: false,
  sectionIndex: -1,
})
export const deleteSingleSectionStoreProxy = { ...deleteSingleSectionStore.value }
