import {
  Box,
  Flex,
  Link as Anchor,
  Stack,
  Text,
  useColorMode,
  useToast,
  // VStack,
} from '@chakra-ui/react'
import { useMutation } from '@apollo/client'
import { Button } from 'components'
import InputWithAddon from 'components/Input/WithAddon'
import PasswordInput from 'components/Input/Password'
import { useState, type FC, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from 'routes/links'
// import SocialLogin from '../SocialLogin'
import { LOGIN_MUTATION } from 'apolloClient/mutations/userLogin'
import Cookies from 'js-cookie'
import { prefetchEditor } from 'utils/effects'
import { POST } from 'utils/base.api'
import { type GetIndividualV2 } from 'routes/types'
import { CurrentUserContext } from 'routes/AuthRouteProvider'

const LoginForm: FC = () => {
  const { colorMode } = useColorMode()
  const navigate = useNavigate()
  const toast = useToast({
    position: 'top',
    containerStyle: {
      zIndex: 9,
    },
  })

  const location = useLocation();
  const isNotIndividual = location.search
 
  const { setCurrentUser } = useContext(CurrentUserContext);
  const [loginValues, setLoginValues] = useState({ usernameOrEmail: '', password: '' })
  const [loginMutation, { loading }] = useMutation(LOGIN_MUTATION)
  const [useUsername, setUseUsername] = useState(true)

  const handleUsernameOrEmailChange: React.ChangeEventHandler<
    HTMLInputElement
  > = e => {
    e.preventDefault();
    const newUsernameOrEmail = e.target.value.replaceAll(' ', '');

    setUseUsername(!newUsernameOrEmail.includes("@"));

    setLoginValues({
      ...loginValues,
      usernameOrEmail: newUsernameOrEmail,
    })
  }
  const handlePasswordChange: React.ChangeEventHandler<
    HTMLInputElement
  > = e => {
    e.preventDefault()
    setLoginValues({
      ...loginValues,
      password: e.target.value,
    })
  }

  const handleSubmit = () => {
    const input = { 
      [useUsername ? 'username' : 'email']: loginValues.usernameOrEmail.toLowerCase().replaceAll(' ', ''),
      password: loginValues.password 
    }
    loginMutation({
      variables: { input },
      onCompleted: data => {
        const { token } = data.login
        Cookies.set('token', token, { secure: true })
        sessionStorage.setItem('token', token)
        prefetchEditor()
        const groupID = localStorage.getItem("groupInviteID");
        if (groupID) {
          POST<GetIndividualV2>(
            {
              ext: 'group/invite/public',
              query: {
                groupID,
              },
            },
            res => {
              if (res.statusCode === 200) {
                setCurrentUser(res.payload);
              }
              navigate(ROUTES.dashboard)
            }
          );
          localStorage.removeItem("groupInviteID"); // remove after login
        }else {
          navigate(ROUTES.dashboard)
        }
      },
      onError: () => {
        toast({
          status: 'error',
          description: 'email or password incorrect',
        })
      },
    })
  }

  return (
    <Stack spacing={4}>
      <Box
        w={"100%"}
      >
        {/* <Text
          onClick={() => {
            setUseUsername(!useUsername)
          }}
          cursor={'pointer'}
          textAlign={'right'}
          fontSize={'12px'}
          textDecoration={"underline"}
          mb={'0.25rem'}
        >
          {useUsername ? 'Use email' : 'Use username'}
        </Text> */}
        <Box w={'full'}>
          <InputWithAddon
            placeholder={
              useUsername ? 'enter username' : 'enter email address'
            }
            name="usernameOrEmail"
            type="text"
            value={loginValues.usernameOrEmail}
            disabled={false}
            showMessage={true}
            leftAddon={useUsername ? 'peiges.co/' : ' '}
            showLabel={false}
            onChange={handleUsernameOrEmailChange}
          />
        </Box>
        <Flex flexDir={'column'}>
          <Box width={{ base: '100%' }}>
            <PasswordInput
              placeholder="password"
              name="password"
              value={loginValues.password}
              disabled={false}
              showMessage={true}
              showLabel={true}
              onChange={handlePasswordChange}
            />
          </Box>
          <Anchor
            href={'/auth/password-reset'}
            alignSelf={'flex-start'}
            fontSize={'14px'}
            mb={'1'}
            onClick={() => {
              localStorage.removeItem('step')
            }}
          >
            Forgot password?
          </Anchor>
        </Flex>
        <Box width={'full'} mt={2}>
          <Button
            text={'Sign in'}
            isLoading={loading}
            w={'full'}
            h={'50px'}
            borderRadius={'8px'}
            maxW={'full'}
            fontWeight={'500'}
            fontSize={'16px'}
            onClick={handleSubmit}
          />
        </Box>
      </Box>
      <Stack pt={6}>
        <Text align={'center'}>
          I don&apos;t have an account?{' '}
          <Anchor
            href={`/auth/${isNotIndividual ? `${isNotIndividual.substring(1)}-` : ''}register`}
            textDecoration={"underline"}
            color={colorMode === 'light' ? '#000000' : '#F5CE6F'}
          >
            Signup
          </Anchor>
        </Text>
      </Stack>
    </Stack>
  )
}

export default LoginForm
