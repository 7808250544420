import { type IIcon } from "./icon";


export const SpacerIcon = ({mode}: IIcon) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
      <path d="M7.72266 14.7773L8.77734 13.7227L10.5 15.4336V12H12V15.4336L13.7227 13.7227L14.7773 14.7773L11.25 18.3047L7.72266 
      14.7773ZM10.5 10.5V7.06641L8.77734 8.77734L7.72266 7.72266L11.25 4.19531L14.7773 7.72266L13.7227 8.77734L12
       7.06641V10.5H10.5ZM22.5 1.5V3H0V1.5H22.5ZM0 19.5H22.5V21H0V19.5Z" fill={mode === 'light' ? "#040404" : "#F5F5F5"}/>
    </svg>
  );
};
