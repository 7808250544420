import { Box, Heading, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import { type FC } from 'react'
import theme from 'theme'
import { type AuthCardInterface } from 'typings/interface'

const AuthCard: FC<AuthCardInterface> = ({
  title,
  subTitle,
  content,
}: AuthCardInterface) => {
  return (
    <Stack
      spacing={8}
      w={{ base: 'full', sm: 'auto' }}
      mx={'auto'}
      minW={{ base: 'auto', md: '395px' }}
      maxW={'395px'}
      py={12}
      px={6}
    >
      <Stack align={'center'}>
        <Heading fontSize={'4xl'} textAlign={'center'}>
          {title}
        </Heading>
        <Text
          align={'center'}
          fontSize={'lg'}
          color={useColorModeValue(
            theme.colors.background.primary._dark,
            theme.colors.background.primary.default,
          )}
          opacity={0.6}
        >
          {subTitle}
        </Text>
      </Stack>
      <Box
        rounded={'lg'}
        bg={useColorModeValue(
          theme.colors.background.primary.default,
          theme.colors.background.primary._dark,
        )}
        p={0}
      >
        {content}
      </Box>
    </Stack>
  )
}

export default AuthCard
