/* eslint-disable no-useless-escape */
import * as yup from "yup";

const loginSchema = yup.object().shape({
  email: yup.string(),
  username: yup.string(),
  password: yup
    .string()
    .min(8, "Minimum of 8 characters")
    .required("Please Enter your password"),
  // .matches(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  //   "Must Contain minimum 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
  // ),
});

export default loginSchema;
