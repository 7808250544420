
import { EditorIcon } from './EditorIcon';
import { TemplatesIcon } from './TemplatesIcon';
import { DashboardIcon } from './DashboardIcon';
import { type IIcons } from './icon.types';
import { GroupsIcon } from './GroupsIcon';
import { PlatformsIcon } from './PlatformsIcon';


export const iconsMap: Record<
  string,
  (mode: IIcons) => JSX.Element
> = {
  'Dashboard': DashboardIcon, // yagni
  'Templates': TemplatesIcon,
  'Editor': EditorIcon,
  'Group': GroupsIcon,
  'Platform': PlatformsIcon,
};
