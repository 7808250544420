import { gql } from '@apollo/client'
import { EditorResponse } from './editorResponse'

export const DELETE_ALL_SECTIONS_MUTATION = gql`
  fragment DeleteAllSectionsInput on REST {
    url: String
  }

  mutation DeleteAllSections($input: DeleteAllSectionsInput!) {
    deleteAllSections(input: $input)
      @rest(type: "DeleteAllSections", method: "POST", path: "/editor/delete-all-sections/graphql") {
        ${EditorResponse}
    }
  }
`
