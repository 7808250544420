import { type ApolloClient, type FetchResult } from '@apollo/client'
import { CURRENT_USER_QUERY, EDITOR_QUERY } from 'apolloClient/queries'

export const updateCurrentUserInCache = (
  client: ApolloClient<object>,
  result: Omit<FetchResult<any>, 'context'>,
) => {
  client.writeQuery({
    query: CURRENT_USER_QUERY,
    data: {
      currentUser: {
        ...result.data.profileUpdate,
        __typename: 'CurrentUser',
      },
    },
  })
}


export const updateEditorInCache = (
  client: ApolloClient<object>,
  result: Omit<FetchResult<any>, 'context'>,
) => {
  client.writeQuery({
    query: EDITOR_QUERY,
    data: {
      editor: {
        ...result.data.updateEditor,
        __typename: 'Editor',
      },
    },
  })
}
