import {
  Box,
  HStack,
  PinInputField,
  PinInput as PinInputWrapper,
  Text,
  useColorMode,
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import theme from 'theme'
import { type FC } from 'react'
import { type PinInterface } from 'typings/interface'

const PinInput: FC<PinInterface> = ({
  errorMessage,
  onChange,
  showMessage,
  value,
}: PinInterface) => {
  const { colorMode } = useColorMode()
  const error = theme.colors.default.error
  const focusBorderColor = theme.colors.default.orange
  const bg = theme.colors.text.primary.default
  const color = theme.colors.background.primary._dark
  const borderColor =
    colorMode === 'dark'
      ? theme.colors.border.default
      : theme.colors.border._dark

  const pinInputFields = [...Array(6)].map((_, index) => (
    <PinInputField
      key={index}
      borderColor={borderColor}
      bg={bg}
      color={color}
    />
  ))

  return (
    <Box w={'full'}>
      <HStack borderRadius={'8px'} justify={'space-between'}>
        <PinInputWrapper
          value={value}
          onChange={onChange}
          type={'number'}
          otp
          size={{ base: 'md', md: 'lg' }}
          colorScheme="blackAlpha"
          focusBorderColor={focusBorderColor}
          placeholder=""
        >
          {pinInputFields}
        </PinInputWrapper>
      </HStack>
      <Box textAlign={'left'} padding="0.3rem 0">
        <Text color={error} fontSize={'12px'} fontWeight="500">
          {showMessage ? errorMessage : ''}
        </Text>
      </Box>
    </Box>
  )
}

export const FormikPinInput: FC<PinInterface> = ({
  onChange,
  showMessage,
  value,
  ...rest
}) => {
  const { handleChange, setFieldValue } = useFormikContext<any>()
  return (
    <PinInput
      {...rest}
      setFieldValue={setFieldValue}
      onChange={handleChange}
      value={value}
      showMessage={showMessage}
    />
  )
}

export default PinInput
