export const ROUTES = {
  auth: '/auth',
  login: '/auth/login',
  notLogin: '/login',
  signup: '/auth/register',
  platformRegister: '/auth/platform-register',
  notPlatformRegister: '/platform-register',
  groupRegister: '/auth/group-register',
  notGroupRegister: '/group-register',
  notSignup: '/register',
  verify: '/verify',
  passwordReset: '/auth/password-reset',
  changeUserName: '/change-username',
  changePassword: '/change-password',
  redirectLanding: '/social-auth-redirect-landing',
  notFound: '*',
  dashboard: '/',
  demodashboard: '/demodb',
  overview: '/dashboard',
  editor: '/editor',
  settings: '/settings',
  analytics: '/analytics',
  pageViews: '/analytics/page-view',
  linkClicks: '/analytics/link-click',
  formSubmissions: '/analytics/form-submissions',
  onboarding: '/onboarding',
  templates: '/templates',
  walkthrough: '/walkthrough',
  profile: '/profile',
  group: '/group',
  groupInvite: '/group-invite/:groupID',
}
