import { TextIcon } from './icons/Text';
import { SocialIcon } from './icons/SocialIcon';
import { LinksIcon } from './icons/Links';
import { TitleIcon } from './icons/Title';
import { SpacerIcon } from './icons/Spacer';
import { ImageTextIcon } from './icons/ImageText';
import { GroupIcon } from './icons/Group';
import { type IIcon } from './icons/icon';
import { type SectionKindType } from 'pages/Editor/types';


export const iconsMap: Record<
  SectionKindType, 
  (mode: IIcon) => JSX.Element
> = {
  'header': TextIcon, // yagni
  'text': TextIcon,
  'social_media': SocialIcon,
  'link': LinksIcon,
  'title': TitleIcon,
  'spacer': SpacerIcon,
  'image_text': ImageTextIcon,
  'group_section': GroupIcon,
};
