import { Flex } from "@chakra-ui/react";
import { UserAvatar } from "compositions";
import { logout } from "helper";
import { type FC } from "react";
import { type IAvatarMenu } from "typings/interface";

const AvatarMenu: FC<IAvatarMenu> = ({
  avatar,
  openRoute,
  verificationStatus,
}: IAvatarMenu) => {
  return (
    <Flex alignItems={"center"} justifyContent={"center"} pos={"relative"}>
      <UserAvatar
        avatar={avatar}
        logOut={logout}
        goto={openRoute}
        verificationStatus={verificationStatus}
      />
    </Flex>
  );
};

export default AvatarMenu;
