export const EditorResponse = `
  individualID
  peigeID
  changeState
  sections {
    _id
    kind
    displayStatus
    createdAt
    updatedAt
    configuration {
      title {
        heading
        alignment
        subHeading
      }
      url
      form {
        title
        description
        field1
        field2
        label
      }
      button {
        text
        url
        addCTA
      }
      text {
        value
        font
        size
      }
      image {
        value
        style
        alignment
      }
      linkView
      bannerImage {
        value
      }      
      icon {
        value
      }
      figureSetting
      socials {
        apps {
          handle
          name
          url
        }
        iconStyle
        alignment
      }
      height
      layout
      groupID
      groupTitle
      groupDescription      
    }
  }
  settings {
    backgroundMode
    backgroundColor
    isGradient
    backgroundImage
    backgroundImagePosition
    backgroundImageOverlay
    backgroundImageEffect
    font
    linksTextColor
    linksBackgroundColor
    linksShape
    linksButtionShouldShow
    linksTextAlignment
  }
`;
