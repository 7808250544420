import { storeProxy, storeProxyHandler, updateStyleSettings } from "utils/effects"
import type { SectionData, SectionKindType } from "pages/Editor/types"
import { 
  type BackgroundImageEffect, type BackgroundImageOverlay, type BackgroundImagePosition, 
  type BackgroundMode, LinksShape, 
  LinksTextAlignment, type PeigeSettings, type ViewContext 
} from "./editor.collection";


export const setSectionKind = new Proxy(
  (sectionKind: SectionKindType, ctx: 'create' | 'edit', currentSectionData: SectionData, currentSectionIndex: number) => {
    storeProxy.sectionKind = sectionKind
    storeProxy.ctx = ctx
    storeProxy.isEditorOpen = true
    storeProxy.currentSectionIndex = currentSectionIndex
    storeProxy.currentSectionData = currentSectionData
    const appRoot = document.querySelector('#root') as HTMLDivElement
    appRoot.style.overflow = 'hidden'
}, storeProxyHandler)


export const unsetSectionKind = new Proxy(() => {
  storeProxy.sectionKind = undefined
  storeProxy.ctx = undefined
  storeProxy.isEditorOpen = false
  storeProxy.currentSectionIndex = 0
  storeProxy.currentSectionData = null as any
  const appRoot = document.querySelector('#root') as HTMLDivElement
  appRoot.style.overflow = 'auto'
}, storeProxyHandler)

export const setStylesModal = new Proxy(() => { storeProxy.isStylesModalOpen = true }, storeProxyHandler)

export const unsetStylesModal = new Proxy(() => { 
  storeProxy.isStylesModalOpen = false;
  storeProxy.mockBGColor = storeProxy.data?.editor?.settings?.backgroundColor || "";
  storeProxy.mockLinksBackgroundColor = storeProxy.data?.editor?.settings?.linksBackgroundColor || ""
  // eslint-disable-next-line no-prototype-builtins
  storeProxy.mockLinksButtionShouldShow = storeProxy.data?.editor?.settings?.hasOwnProperty("linksButtionShouldShow") ? 
    storeProxy.data?.editor?.settings?.linksButtionShouldShow : true
  storeProxy.mockLinksShape = storeProxy.data?.editor?.settings?.linksShape || LinksShape.ROUNDED
  storeProxy.mockLinksTextAlignment = storeProxy.data?.editor?.settings?.linksTextAlignment || LinksTextAlignment.LEFT
  storeProxy.mockLinksTextColor = storeProxy.data?.editor?.settings?.linksTextColor || ""
}, storeProxyHandler)

export const setViewContext = new Proxy((viewContext: ViewContext) => { storeProxy.viewContext = viewContext }, storeProxyHandler)

export const setStylesSettings = new Proxy((settings: PeigeSettings) => {
  storeProxy.isSaving = true;
  updateStyleSettings({settings})
}, storeProxyHandler);

// mock bg color and mock bg image are used as temporary aliases for bgColor and bgImage while the user is in the style modal.
// this is to allow the styles modal text color to always be the same with that of the preview when the preview is eventually saved
// check the usage of mockBGColor in any of the sections alongside with viewContext
export const setMockBGColor = new Proxy((mockBGColor: string) => { storeProxy.mockBGColor = mockBGColor }, storeProxyHandler)
export const setMockFont = new Proxy((mockFont: string) => { storeProxy.mockFont = mockFont }, storeProxyHandler)
export const setMockBGOverlay = new Proxy((mockBGOverlay: BackgroundImageOverlay) => { storeProxy.mockBGOverlay = mockBGOverlay }, storeProxyHandler)
export const setMockBGPosition = new Proxy((mockBGPosition: BackgroundImagePosition) => { storeProxy.mockBGPosition = mockBGPosition }, storeProxyHandler)
export const setMockBGEffect = new Proxy((mockBGEffect: BackgroundImageEffect) => { storeProxy.mockBGEffect = mockBGEffect }, storeProxyHandler)
export const setMockBGMode = new Proxy((mockBGMode: BackgroundMode) => { storeProxy.mockBGMode = mockBGMode }, storeProxyHandler)
export const setMockLinksTextColor = new Proxy((mockLinksTextColor: string) => { storeProxy.mockLinksTextColor = mockLinksTextColor }, storeProxyHandler)
// eslint-disable-next-line max-len
export const setMockLinksTextAlignment = new Proxy((mockLinksTextAlignment: LinksTextAlignment) => { storeProxy.mockLinksTextAlignment = mockLinksTextAlignment }, storeProxyHandler)
// eslint-disable-next-line max-len
export const setMockLinksBackgroundColor = new Proxy((mockLinksBackgroundColor: string) => { storeProxy.mockLinksBackgroundColor = mockLinksBackgroundColor }, storeProxyHandler)
export const setMockLinksShape = new Proxy((mockLinksShape: LinksShape) => { storeProxy.mockLinksShape = mockLinksShape }, storeProxyHandler)
// eslint-disable-next-line max-len
export const setMockLinksButtionShouldShow = new Proxy((mockLinksButtionShouldShow: boolean) => { storeProxy.mockLinksButtionShouldShow = mockLinksButtionShouldShow }, storeProxyHandler)
// export const setMockPeigeSettings = new Proxy((mockPeigesettings: PeigeSettings) => { storeProxy. = mockBGMode }, storeProxyHandler)
