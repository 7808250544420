import { gql } from '@apollo/client'
import { EditorResponse } from './editorResponse'

export const UNDO_DELETE_ALL_SECTIONS_MUTATION = gql`
  fragment UndoDeleteAllSectionsInput on REST {
    url: String
  }

  mutation UndoDeleteAllSections($input: UndoDeleteAllSectionsInput!) {
    undoDeleteAllSections(input: $input)
      @rest(type: "UndoDeleteAllSections", method: "POST", path: "/editor/undo-delete-all-sections/graphql") {
        ${EditorResponse}
    }
  }
`
