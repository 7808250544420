import { gql } from '@apollo/client';

export const PLATFORM_SIGNUP_MUTATION = gql`
  fragment PlatformSignupInput on REST {
    name: String
    username: String
    email: String
    password: String
  }

  mutation UserPlatformSignup($input: PlatformSignupInput!) {
    platformSignup(input: $input) @rest(type: "UserPlatformSignup", method: "POST", path: "/auth/signup-platform") {
      message
      token
    }
  }
`;