import { gql } from '@apollo/client';

export const CURRENT_USER_DASHBOARD_QUERY = gql`
  query CurrentUserDashboard {
    dashboard @rest(type: "UserDashboard", path: "/dashboard/individual") {
      statusCode
      message
      payload {
        overview {
          name
          value
          percentage
          percentageType
        }
        firstname
        lastname
        username
      }
    }
  }
`;