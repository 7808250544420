import { type FC } from 'react'
import { Box, Flex, Image, Text } from '@chakra-ui/react'

import theme from 'theme'
import { List } from 'Lib'
import EditorIcons from 'compositions/EditorIcons'
import { type SectionKindType } from 'pages/Editor/types'
import { type GeneralNavInterface } from 'typings/interface'
import { setSectionKind, setStylesModal } from 'utils/store/set.state'

import { Nav } from 'Layout/Dashboard/composition'
import AvatarMenu from 'Layout/Dashboard/composition/AvatarMenu'
import LogoSection from 'Layout/Dashboard/composition/LogoSection'

const BottomNav: FC<GeneralNavInterface> = ({
  colorMode,
  // editorActive,
  isEditor,
  isWeb,
  username,
  avatar,
  openRoute,
  verificationStatus,
  shouldIncludeGroup,
  isPlatform,
  isGroupIconClickable
}: GeneralNavInterface) => {
  const darkBg = theme.colors.background.primary._dark
  const lightBg = theme.colors.background.primary.default

  const sendSignal = (kind: SectionKindType) => {
    setSectionKind(kind, 'create', null as any, 0)
  };

  return (
    <Box
      display={isEditor || !isWeb ? 'block' : 'none'}
      h={'60px'}
      left={0}
      right={0}
      bottom={0}
      pos={'fixed'}
      zIndex={8}
      pl={isEditor ? { base: 0, md: '4.5rem' } : 0}
    >
      <Flex
        bg={colorMode === 'light' ? lightBg : darkBg}
        boxShadow={'5px -20px 40px 0 rgba(142, 151, 158, 0.015)'}
        justify={isEditor ? 'center' : 'space-between'}
        align={'center'}
        p={'10px'}
        w={'full'}
        maxW={isEditor ? '768px' : '980px'}
        h={'full'}
        m={'0 auto'}
        gap={isEditor ? '30px' : 0}
      >
        {isEditor ? (
          <>
            <Flex
              ml={2}
              gap={1}
              justify={'center'}
              align={'center'}
              h={'fit-content'}
              w={'fit-content'}
              cursor={ 'pointer'}
              onClick={setStylesModal}
            >
              <Text fontSize={'14px'} fontWeight={'medium'}>Style</Text>
              <Image
                src={'/images/colour-lens.svg'}
                w={'20px'}
                h={'20px'}
                alt={'colour'}
                filter={colorMode === 'light' ? 'invert(100)' : 'invert(0)'}
                loading="lazy"
              />
            </Flex>
            <Flex
              overflowX={'scroll'}
              className="no-scrollbar"
              align={'center'}
              gap={{base: '16px', md: '24px'}}
              pr={2}
            >
              {
                List.editorIconList.map((icon) => (
                  <EditorIcons
                    name={icon.name}
                    value={icon.value}
                    handleOpenDrawer={() => {
                      sendSignal(icon.value)
                    }}
                    isActive={true}
                    key={icon.name}
                  />
                ))
              }
              {
                shouldIncludeGroup &&
                (
                  <EditorIcons
                    name={"Group"}
                    value={"group_section"}
                    handleOpenDrawer={() => {
                      sendSignal("group_section")
                    }}
                    isActive={isGroupIconClickable}
                  />
                )
              }
            </Flex>
          </>
        ) : !isWeb && !isEditor ? (
          <>
            <LogoSection colorMode={colorMode} />
            <Nav 
              openRoute={openRoute} 
              shouldIncludeGroup={shouldIncludeGroup}
              isPlatform={isPlatform}
            />
            <AvatarMenu
              avatar={avatar}
              username={username}
              openRoute={openRoute}
              verificationStatus={verificationStatus}
            />
          </>
        ) : (
          <></>
        )}
      </Flex>
    </Box>
  )
}

export default BottomNav
