import { gql } from "@apollo/client";

export const USER_QUERY_FRAGMENT = gql`
  fragment CurrentUserFragmment on CurrentUser {
    statusCode
    message
    payload {
      _id
      avatar
      email
      isVerified
      isNewUser
      isPeigePublished
      isPeigeShared
      hasSentFeedback
      thingsToDo {
        isComplete
        toDos {
          claimUsername
          verifyEmail
          completeOnboarding
          addSection
          fillYourNames
          uploadApicture
        }
      }
      profile {
        firstname
        lastname
        username
      }
      preferences {
        isPublished
        peigePublishMode
        peigeID
      }
      meta {
        title
        description
        favicon
      }
      locationInfo {
        address
        requestedLocationAt
        hasRequestedLocation
        approvalStatus
        coordinates {
          lng
          lat
        }
      }
      currentGroupBranding {
        useGroupBranding
        useGroupName
        groupName
        groupLogo
        groupID
      }
      autoPublishButtonText
      role
      groupID
      pendingGroupInviteList
      platformAccountID
      apiKey
    }
  }
`;
