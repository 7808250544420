import { gql } from '@apollo/client'
import { EditorResponse } from './editorResponse'

export const DELETE_SINGLE_SECTION_MUTATION = gql`
  fragment DeleteSingleSectionInput on REST {
    sectionID: String
  }

  mutation DeleteSingleSection($input: DeleteSingleSectionInput!) {
    deleteSingleSection(input: $input)
      @rest(type: "DeleteSingleSection", method: "POST", path: "/editor/delete-single-section/graphql") {
        ${EditorResponse}
    }
  }
`
