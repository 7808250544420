import {
  Box,
  Textarea as TextareaCmp,
  Text,
  useColorMode,
} from '@chakra-ui/react'
import { useFormikContext } from 'formik'
import './textarea.css'
import { type ITextArea } from 'typings/interface'
import { useState, type FC } from 'react'

const TextArea: FC<ITextArea> = ({
  placeholder,
  _placeholder,
  id,
  h,
  className,
  tAType,
  background,
  border,
  required,
  name,
  disabled,
  value,
  values,
  onChange,
  errorMessage,
  showMessage,
  color,
  width,
  borderRadius,
  hideCaret,
  _focus,
  label,
  ...rest
}: ITextArea) => {
  const { colorMode } = useColorMode()
  const [isActive, setIsActive] = useState(false)
  function handleTextChange(text: string): void {
    if (text !== '') {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }
  return (
    <>
      <Box
        position={'relative'}
        display="flex"
        flexDirection={'column'}
        outline="none"
        borderRadius={borderRadius ?? '8px'}
        w={width ?? 'full'}
        id="custom-text-area-wrapper"
        background={background ?? 'transparent'}
        border={
          border || `1px solid ${
                colorMode === 'light'
                  ? '#00000040'
                  : 'rgba(255, 255, 255, 0.5)'
              }`
        }
        overflow={'hidden'}
      >
        <TextareaCmp
          sx={{ caretColor: `${hideCaret ? 'transparent' : ''}` }}
          id={id}
          h={h}
          className={className ?? tAType ?? ''}
          autoComplete="off"
          padding={'1rem'}
          background={'transparent'}
          // placeholder={placeholder}
          required={required ?? false}
          name={name}
          disabled={disabled ?? false}
          value={values ? values[name] : value}
          onChange={(e: any) => {
            onChange?.(e)
            handleTextChange(e.target.value)
          }}
          border={0}
          _hover={{
            border: 0,
          }}
          //   _focus={{
          //     boxShadow: "none",
          //     border: errorMessage
          //       ? "0"
          //       : _focus
          //       ? _focus
          //       : `0.5px solid ${theme.colors.light.vLightGray}`,
          //     borderRadius: borderRadius ?? "8px",
          //   }}
          _disabled={{ opacity: 0.9 }}
          fontSize={'16px'}
          color={color ?? `#525252`}
          fontWeight="400"
          {...rest}
        />
        <label
          id="input-label"
          htmlFor={'text'}
          className={isActive ? 'Active' : ''}
          style={{
            transform: `${
              value ?? values?.[name] ? 'translate(15px, 2px) scale(0.6)' : ''
            }`,
            zIndex: `${value ?? values?.[name] ? '2' : ''}`,
            color:`${color}`,
          }}
        >
          {placeholder}
        </label>
      </Box>
      <Box textAlign={'left'} padding="0.3rem 0">
        <Text color="#FF8585" fontSize={'12px'} fontWeight="400" pl="1rem">
          {showMessage ? errorMessage : ''}
        </Text>
      </Box>
    </>
  )
}

export const FormikFormTextArea: FC<ITextArea & {
  name: string
  showMessage: boolean
  value?: any
}> = ({
  name,
  showMessage,
  value,
  isNumericFormat,
  hideCaret,
  ...rest
}: ITextArea & {
  name: string
  showMessage: boolean
  value?: any
}) => {
  const { handleChange, values, errors, setFieldValue } =
    useFormikContext<any>()
  const hasError = errors[name] ?? ''

  return (
    <TextArea
      {...rest}
      isNumericFormat={isNumericFormat}
      setFieldValue={setFieldValue}
      name={name}
      onChange={handleChange}
      value={values[name] ?? value}
      showMessage={showMessage}
      errorMessage={hasError}
    />
  )
}

export default TextArea
