import { type IIcon } from "./icon";


export const ImageTextIcon = ({mode}: IIcon) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 28 28" fill="none">
      <rect x="1.2" y="1.2" width="25.6" height="25.6" rx="2.8" stroke={mode === 'light' ? "#040404" : "#F5F5F5"} strokeWidth="1.6"/>
      <path d="M24 13.9C24 14.3971 23.5971 14.8 23.1 14.8H20H16.9C16.4029 14.8 16 14.3971 16 13.9C16 13.4029 16.4029 
      13 16.9 13H23.1C23.5971 13 24 13.4029 24 13.9ZM24 9.75C24 9.33579 23.6642 9 23.25 9H16.75C16.3358 9 16 9.33579 
      16 9.75C16 10.1642 16.3358 10.5 16.75 10.5H20H23.25C23.6642 10.5 24 10.1642 24 9.75ZM16 17.75C16 18.1642 16.3358 
      18.5 16.75 18.5H19.5H23.25C23.6642 18.5 24 18.1642 24 17.75C24 17.3358 23.6642 17 23.25 17H16.75C16.3358 17 16 17.3358
       16 17.75ZM14 11V17C14 18.1 13.1 19 12 19H6C4.9 19 4 18.1 4 17V11C4 9.9 4.9 9 6 9H12C13.1 9 14 9.9 14 11ZM10.5266 17C11.3457 
       17 11.8174 16.0692 11.333 15.4086L11.0805 15.0643C10.6887 14.53 9.89494 14.5176 9.48658 15.0394L9.09852 15.5352C8.79295 15.9257
        8.20306 15.9288 7.89333 15.5417C7.58787 15.1598 7.00819 15.1567 6.69855 15.5351L6.63513 15.6126C6.18133 16.1673 6.57595 17 
        7.29258 17H10.5266Z" fill={mode === 'light' ? "#040404" : "#F5F5F5"}/>
    </svg>    
  );
};
