import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useColorMode,
  useMediaQuery,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  // useDisclosure,
} from '@chakra-ui/react'
import { type FC } from 'react'
import theme from 'theme'
import { type ChakraModalInterface } from 'typings/interface'

// type DemoModalProps = {

// };

const DemoModal: FC<ChakraModalInterface> = ({
  isOpen,
  onOpen,
  onClose,
  useDefaultButton,
  id,
  blockScrollOnMount,
  isCentered,
  closeOnEsc,
  closeOnOverlayClick,
  children,
  bg,
  padding,
  maxW,
  maxH,
  borderRadius,
}) => {
  // const { isOpen, onOpen, onClose } = useDisclosure()
  const { colorMode } = useColorMode()
  const themeBlack = theme.colors.default.black
  const themeWhite = theme.colors.default.white
  const [isLargerThan600] = useMediaQuery('(min-width: 600px)')
  // const [isLargerThan768] = useMediaQuery('(min-width: 768px)')
  return (
    <>
      <Modal
        isOpen={isOpen && isLargerThan600}
        onClose={onClose}
        isCentered={true}
        closeOnOverlayClick={true}
        motionPreset="none"
      >
        <ModalOverlay
          bg={'rgba(34,36,44,.2)'}
          backdropFilter={'blur(10px)'}
        />

        <ModalContent
          autoFocus={false}
          p={padding ?? 6}
          borderRadius={
            borderRadius ?? isLargerThan600 ? '25px' : '25px 25px 0 0'
          }
          bg={bg ?? (colorMode === 'light' ? themeWhite : themeBlack)}
          maxW={maxW}
          maxH={maxH ?? '95vh'}
          boxShadow={'none'}
          overflowY={'auto'}
          className="no-scrollbar"
        >
          {useDefaultButton && <ModalCloseButton />}
          <ModalBody>{children}</ModalBody>
        </ModalContent>
      </Modal>
      {
        !isLargerThan600 ? 
        <Drawer
          isOpen={isOpen}
          placement="bottom"
          onClose={onClose}
          autoFocus={false}
          closeOnOverlayClick={true}
        >
          <DrawerOverlay background="rgba(0, 0, 0, 0.5)" />
          <DrawerContent
            bg={bg ?? (colorMode === 'light' ? themeWhite : themeBlack)}
            roundedTop={'2xl'}
            zIndex={'tooltip'}
            maxH={'89vh'}
          >
            <DrawerBody>{children}</DrawerBody>
          </DrawerContent>
        </Drawer>
       : <></>
      }
    </>
  )
}
export default DemoModal
