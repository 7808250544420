import { type IIcon } from "./icon";


export const SocialIcon = ({mode}: IIcon) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
      <path d="M13.6 10.9C13.7 11 13.8 11 14 11H21C21.6 11 22 10.6 22 10C22 9.8 22 9.7 21.9 9.6L18.4 2.6C18.1 2.1 17.5 1.9 17
       2.2C16.9 2.3 16.7 2.4 16.6 2.6L13.1 9.6C12.9 10 13.1 10.6 13.6 10.9ZM17.5 5.2L19.4 9H15.6L17.5 5.2ZM6.5 2C4 2 2 4 2 6.5C2 
       9 4 11 6.5 11C9 11 11 9 11 6.5C11 4 9 2 6.5 2ZM6.5 9C5.1 9 4 7.9 4 6.5C4 5.1 5.1 4 6.5 4C7.9 4 9 5.1 9 6.5C9 7.9 7.9 9 6.5 
       9ZM10.7 13.3C10.3 12.9 9.7 12.9 9.3 13.3L6.5 16.1L3.7 13.3C3.3 12.9 2.7 12.9 2.3 13.3C1.9 13.7 1.9 14.3 2.3 14.7L5.1 17.5L2.3
        20.3C1.9 20.7 1.9 21.3 2.3 21.7C2.7 22.1 3.3 22.1 3.7 21.7L6.5 18.9L9.3 21.7C9.7 22.1 10.3 22.1 10.7 21.7C11.1 21.3 11.1 20.7 
        10.7 20.3L7.9 17.5L10.7 14.7C11.1 14.3 11.1 13.7 10.7 13.3ZM21 13H14C13.4 13 13 13.4 13 14V21C13 21.6 13.4 22 14 22H21C21.6 22
         22 21.6 22 21V14C22 13.4 21.6 13 21 13ZM20 20H15V15H20V20Z" fill={mode === 'light' ? "#040404" : "#F5F5F5"}/>
    </svg>
  );
};
