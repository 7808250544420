import { ChakraProvider, theme } from '@chakra-ui/react'
import { ErrorBoundary } from 'react-error-boundary'
import { BrowserRouter as Router } from 'react-router-dom'
import './App.css'
import { GlobalStyles } from './theme/GlobalStyles'
import { ApolloProvider } from '@apollo/client'
import { Global } from '@emotion/react'
import Routes from 'routes'
import { type FC } from 'react'
import { client } from 'apolloClient'
import ErrorPage from 'components/ErrorPage'
import Fonts from 'theme/Font'

export const App: FC = () => {
  const ErrorFallback: FC = () => {
    return <ErrorPage />
  }
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error, info) => {
        // TODO: Behaviour is currently incosistent
        // will send logs of errors to the backend
        console.log('an error occured')
        console.log({ error })
        console.log({ info })
      }}
    >
      <ChakraProvider theme={theme}>
        <Fonts />
        <Global styles={GlobalStyles} />
        <ApolloProvider client={client}>
          <Router>
            <Routes />
          </Router>
        </ApolloProvider>
      </ChakraProvider>
    </ErrorBoundary>
  )
}
