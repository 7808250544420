import { type LinkItemProps } from "typings/interface";

const LinkItems: LinkItemProps[] = [
  { name: "Dashboard", route: "/dashboard" },
  { name: "Editor", route: "/editor" },
  { name: "Templates", route: "/templates" },
  { name: "Group", route: '/group' },
  // { name: "Platform", route: '/platform' }
];

const Menu = {
  LinkItems
}

export default Menu;
